import Ably from "ably";
import { atom } from "nanostores";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { ReactNode } from "react";
import { toast } from "react-toastify";

import API from "@/client/api";
import trackerWeb from "@/client/trackerWeb";
import errorTracker from "@/lib/errorTracker";
import { logger as globalLogger, Level, loggerWithPrefix } from "@/lib/logger";
import { exportStores } from "@/stores";
import {
  CookieFragments,
  CookieStatus,
  ServerProps,
  UserMeta,
  UserWithMeta,
  WorkspaceData,
} from "@/types";
import { UserProfile } from "@prisma/client";

const logger = loggerWithPrefix("[uiStore]");

export interface InputModalField {
  placeholder?: string;
  currentValue?: string;
  multiline?: boolean;
}

class UIStore {
  realtime: Ably.Realtime | undefined;

  sidebarVisible = atom<boolean>(false);

  user = atom<UserWithMeta | null>(null);

  router: ReturnType<typeof useRouter> | undefined;

  showConfirmModal = atom<
    | {
        type: "info" | "warning" | "danger";
        title: string;
        subtitle?: string;
        body?: ReactNode;
        onClick?: () => void | Promise<void>;
      }
    | undefined
  >();

  showInputModal = atom<
    | {
        type: "add" | "edit" | "info" | "warning" | "danger";
        title: string;
        subtitle?: string;
        instructions?: React.ReactElement;
        fields: InputModalField[];
        onSubmit: (values: string[]) => void | Promise<void>;
      }
    | undefined
  >();

  showInvitesModal = atom<boolean>(false);

  liCookieStatus = atom<CookieStatus | "no-extension" | "unknown">("unknown");

  userProfile = atom<UserProfile | null>(null);

  autofocusSearchBar = atom<boolean>(false);

  // even though we can use media classes, this prevents rendering of unnecessary components
  smallScreen = atom<boolean>(false);

  recentConversations = atom<{ id: string; type: string; context: string }[]>([]);

  currentHash = atom<string | null>(null);

  onboarding = atom<boolean>(false);

  init = (props: ServerProps) => {
    this.smallScreen.set(document.body.clientWidth < 800);

    if (isWorkspaceData(props) && props.cookieFragments) {
      if (!this.user.get() && props.user) this.user.set(props.user);
      this.checkCookieFragments(props.cookieFragments);
    }

    const hashChange = () => {
      const hash = window.location.hash;
      const id = hash.startsWith("#") ? hash.substring(1) : hash;
      if (id) this.currentHash.set(id);
      else if (this.currentHash.get()) this.currentHash.set(null);
    };
    window.addEventListener("hashchange", hashChange);
    hashChange();
  };

  hasSet = false;
  setUser = (user: UserWithMeta) => {
    this.user.set(user);
    const isDev = this.showDevTools();
    void exportStores();

    if (process.env.NODE_ENV != "development") {
      globalLogger.setLevel(isDev ? Level.INFO : Level.WARN);
    }

    if (!this.hasSet) {
      trackerWeb.identify(user.id, { email: user.email, name: user.name, userId: user.id });
      errorTracker.setUser(user);
      this.hasSet = true;
      if (isDev) trackerWeb.debugMode();
    }
  };

  getConnectedRealtime = async (): Promise<Ably.Realtime> => {
    if (!this.realtime) {
      this.realtime = new Ably.Realtime(
        !uiStore.user.get() ? {} : { authUrl: location.origin + "/api/ably/token" },
      );
    }
    if (this.realtime.connection.state === "connected") {
      return this.realtime;
    }

    return new Promise<Ably.Realtime>((res) => {
      const realtime = this.realtime;
      if (!realtime) return;
      realtime.connection.once("connected", () => {
        res(realtime);
      });
    });
  };

  toggleSidebar = () => {
    this.sidebarVisible.set(!this.sidebarVisible.get());
  };

  isSmallScreen = () => typeof document != "undefined" && document.body.clientWidth < 640;

  updateUser = async (updates: { name?: string; image?: string | null }) => {
    const user = this.user.get();
    if (!user) return;
    const updated = await API.user.update(updates);
    this.user.set({ ...user, ...updated });
  };

  updateUserMeta = async (updates: UserMeta) => {
    const user = this.user.get();
    if (!user) return;
    const updated = await API.updateUserMeta({ meta: updates });
    this.user.set({ ...user, meta: updated });
    return updated;
  };

  showDevTools = () => {
    const user = this.user.get();
    if (!user) return false;
    if (user.email?.endsWith("@distill.fyi") || user.meta?.dev) {
      return true;
    }
    return false;
  };

  routeTo = (path: string) => {
    if (this.router) this.router.push(path);
    else location.href = path;
  };

  reloadPage = () => {
    if (this.router) this.router.refresh();
    else location.reload();
  };

  loadUserProfile = async () => {
    if (this.userProfile.get()) return this.userProfile.get();
    const profile = (await API.userProfile.get()) || ({} as UserProfile);
    this.userProfile.set(profile);
    return profile;
  };

  focusSearchBar = () => {
    this.autofocusSearchBar.set(true);
    setTimeout(() => {
      this.autofocusSearchBar.set(false);
    }, 500);
  };

  logOut = () => {
    trackerWeb.logout();
    localStorage.removeItem("$set");
    void signOut({ callbackUrl: "/" });
  };

  checkedCookies = false;
  checkCookieFragments = (fragments: CookieFragments) => {
    if (this.checkedCookies) return;
    this.checkedCookies = true;
    logger.info("checkCookieFragments", fragments);
    const domains = Object.keys(fragments);
    domains.forEach(async (domain) => {
      const cookieTails = fragments[domain];
      const result = await this.checkCookies(cookieTails, domain);
      if (domain.includes("linkedin.com")) this.liCookieStatus.set(result);
    });
  };

  checkCookies = async (
    cookieFragments: string[],
    hostname: string,
  ): Promise<CookieStatus | "no-extension"> => {
    const origUser = localStorage.getItem("orig_user");
    if (origUser) {
      // when we are impersonating other users, skip cookie check
      const user = JSON.parse(origUser) as UserWithMeta;
      if (user.id && this.user.get()?.id != user.id) {
        return cookieFragments.length > 0 ? "valid" : "missing";
      }
    }

    const user = this.user.get();
    // we don't know how to request non-linkedin cookies yet
    logger.info("checking for cookies", user, hostname);
    if (!user || !hostname.includes("linkedin.com") || typeof chrome == "undefined")
      return cookieFragments.length > 0 ? "valid" : "missing";
    // give the chrome extension some time to complete
    const foundChromeCookie = await extractExtensionCookie(user.id);
    logger.info("foundChromeCookie", foundChromeCookie);

    const foundValue = foundChromeCookie?.cookie;
    if (foundValue && cookieFragments.find((f) => foundValue.endsWith(f))) {
      trackerWeb.capture("chrome-cookie-matched");
      logger.info("[li] chrome cookie matched.");
      return "valid";
    } else if (foundValue) {
      trackerWeb.capture("chrome-cookie-no-match");
      logger.info("[li] chrome cookie no match", foundChromeCookie.cookie);
      // we got a new cookie, save this bad boy
      try {
        const li_rm = foundChromeCookie.li_rm;
        const promise = API.updateCookie({
          url: "https://www.linkedin.com",
          name: "li_at",
          value: foundValue,
          meta: {
            li_rm,
            ua: navigator.userAgent,
          },
        });

        await promise;
        return "valid";
      } catch (e) {
        logger.warn(e);
        return "invalid";
      }
    } else if (foundChromeCookie) {
      trackerWeb.capture("chrome-cookie-empty", { cookies: cookieFragments.length });
      return cookieFragments.length > 0 ? "valid" : "missing";
    } else {
      trackerWeb.capture("no-chrome-extension", { cookies: cookieFragments.length });
      return cookieFragments.length > 0 ? "valid" : "no-extension";
    }
  };

  ingestUtmSource = (
    type: string,
    details: Record<string, string | number | boolean | null | undefined>,
  ) => {
    const utmSource = this.getAndDeleteUrlParam("utm_source");
    if (utmSource) {
      trackerWeb.capture(`${type}-${utmSource}`, details);
    }
  };

  getAndDeleteUrlParam = (urlParam: string) => {
    if (window) {
      const urlParams = new URLSearchParams(window.location.search);
      const value = urlParams.get(urlParam);
      if (value) {
        urlParams.delete(urlParam);
        const newUrl =
          urlParams.toString().length ?
            `${window.location.pathname}?${urlParams.toString()}`
          : window.location.pathname;
        window.history.replaceState({}, "", newUrl);
      }
      return value;
    }
    return undefined;
  };
}

export const uiStore = new UIStore();

export const PROD_CHROME_EXTENSION = "kidifhledoijjifmngjkaclhdoffdneg";

const DEV_CHROME_EXTENSION_IDS = [
  // add your development extension id
  "fhomjjmbamoccgioeocggddaefiejgnl",
  "fcabjmklleinfceeobnlgdeakoepgfeo",
  "gmpjoopcdmklmgbipkhgkaemlgeolimg",
  "clffdpjifkbfhcnahbgponoeljliklkh",
  "jojhghkacgcgohcgmemekaafjpfjjkkp",
  "lefhlbmndglddnheckgmohgkkifdlppi",
  "ahcaclkgbnklbhimgbpmllfphadbbgpd",
  "gbkfjhilcgphfffclnabfoiaoallmiko",
  "laegdoeciejjdkleiojkaccagaifhiga",
];

export const CHROME_EXTENSION_IDS =
  process.env.NODE_ENV == "development" ?
    [...DEV_CHROME_EXTENSION_IDS, PROD_CHROME_EXTENSION]
  : [PROD_CHROME_EXTENSION];

function isWorkspaceData(props: ServerProps): props is WorkspaceData {
  return "cookieFragments" in props;
}

async function extractExtensionCookie(userId: string, timeout: number = 1000) {
  return new Promise<{
    cookie: string | null;
    id: string;
    li_rm?: string;
  } | null>((res) => {
    if (!chrome?.runtime) {
      res(null);
      return;
    }

    let toCheckCount = CHROME_EXTENSION_IDS.length;
    const payload = {
      user: userId,
      cookieCheck: true,
    };
    CHROME_EXTENSION_IDS.forEach((extensionId) => {
      try {
        chrome.runtime.sendMessage(
          extensionId,
          payload,
          (response: {
            allCookies?: chrome.cookies.Cookie[];
            cookie: string | null;
            li_rm?: string;
          }) => {
            // we access the last error so we don't log to console.
            const _err = chrome.runtime.lastError;
            if (response) {
              chrome?.runtime?.sendMessage(
                extensionId,
                { message: "version" },
                (response: { version: string }) => {
                  trackerWeb.capture("chrome-extension-version", response);
                },
              );

              logger.info("chrome extension response:", response, extensionId);
              if (response.allCookies && response.allCookies.length > 1) {
                trackerWeb.capture("multiple-cookies-detected", {
                  cookies: JSON.stringify(response.allCookies),
                });
              }
              res({ cookie: response.cookie, id: extensionId, li_rm: response.li_rm });
            }
          },
        );
      } catch (e) {
        // doesn't exist, continue to next one
        toCheckCount--;
        logger.info("error checkin, remaining", toCheckCount);
        if (toCheckCount == 0) {
          res(null);
        }
      }
    });
  });
}
