import { cleanBaseUrl } from "@/lib/utils";

interface SocialPlatform {
  service: string;
  regex: RegExp;
  parseUsername: (match: RegExpExecArray) => string;
}

/* 
  The following section is adapted from the social-profile-url-parser library, available at
  https://github.com/Salesflare/social-profile-url-parser, into this project.
*/

const socialPlatforms: SocialPlatform[] = [
  {
    service: "aboutme",
    regex: /https?:\/\/(www\.)?about\.me\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "angellist",
    regex: /https?:\/\/(www\.)?angel\.co\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "behance",
    regex: /https?:\/\/(www\.)?behance\.(com|net)\/([^ "/\n]+)/i,
    parseUsername: (match) => match[3],
  },
  {
    service: "crunchbase",
    regex: /https?:\/\/(www\.)?crunchbase\.com\/organization\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "dribbble",
    regex: /https?:\/\/(www\.)?dribbble\.com\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "facebook",
    regex:
      /(https:\/\/(www\.)?facebook\.com\/(?!story\.php)(profile\.php\?id=([0-9]+)|[A-Za-z0-9.]+))/i,
    // match[4] exists if this is a profile.php URL
    parseUsername: (match) => match[4] || match[3] || "",
  },
  {
    service: "github",
    regex: /https?:\/\/(www\.)?github\.com\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "instagram",
    regex: /https?:\/\/(www\.)?instagram\.com\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "linkedin",
    regex:
      /https?:\/\/([A-Za-z_]{0,3}\.)?linkedin\.com\/(((sales\/)?(in|pub|people|company|companies|organization|edu|school|groups)\/)|(profile\/view\?id=[a-zA-Z]))([^ "/\n]+)/i,
    parseUsername: (match) => match[7],
  },
  {
    service: "medium",
    regex: /https?:\/\/(www\.)?medium\.com\/@?([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "pinterest",
    regex: /https?:\/\/([A-Za-z_]{0,3}\.)?pinterest\.[a-zA-Z.]+\/([^ +/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "pitchbook",
    regex: /https?:\/\/(www\.)?pitchbook\.com\/profiles\/investor\/([^ "/\n]+)/i,
    parseUsername: (match) => "",
  },
  {
    service: "quora",
    regex: /https?:\/\/(www\.)?quora\.com\/profile\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "reddit",
    regex: /https?:\/\/(www\.)?reddit\.com\/user\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "twitter",
    regex:
      /https?:\/\/((www|mobile)\.)?(twitter|x)\.com\/(?!i\/status\/)([^/]+)(?:\/status\/\d+)?/i,
    parseUsername: (match) => match[4],
  },
  {
    service: "vk",
    regex: /https?:\/\/(www\.)?vk\.com\/([^ "/\n]+)/i,
    parseUsername: (match) => match[2],
  },
  {
    service: "youtube",
    regex: /https?:\/\/([A-Za-z_]{0,3}\.)?youtube\.com\/(user\/|channel\/|c\/)?([^ "/\n]+)/i,
    parseUsername: (match) => match[3],
  },
];

export interface SocialInfo {
  service: string;
  username: string;
  url: string;
}

export function isValidProfileUrl(url: string): boolean {
  const cleanedUrl = cleanBaseUrl(url);
  return socialPlatforms.some(({ regex }) => {
    const match = regex.exec(cleanedUrl);
    return match && match[0] === cleanedUrl;
  });
}

export function extractSocialInfo(url: string): SocialInfo | null {
  for (const platform of socialPlatforms) {
    const match = platform.regex.exec(url);
    if (match) {
      return {
        service: platform.service,
        username: platform.parseUsername(match).split("?")[0],
        url: match.input,
      };
    }
  }
  return null;
}
