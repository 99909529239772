import { LinkedinCompanyType } from "@/external/mixrank.types";
import { EntityType, EntityWithAttributes } from "@/types";
import { Entity, Relationship } from "@prisma/client";

export enum ExperienceType {
  Work = "work",
  Investment = "investment",
  Advisor = "advisor",
  BoardMember = "board-member",
  SideProject = "side-project",
  Partner = "partner",
  Member = "member",
  Volunteer = "volunteer",
}

export enum RelationshipSource {
  LinkedIn = "linkedin",
  Crunchbase = "crunchbase",
  NFX = "nfx",
  Manual = "manual",
}

export const PersonCompanyRelationship = {
  WorkedAt: "worked-at",
  InvestedIn: "invested-in",
  Founded: "founded",
  VolunteeredAt: "volunteered-at",
  EducatedAt: "educated-at",
  Advised: "advised",
  OtherExperience: "other-experience", // catch-all for non-work experience
} as const;

// This is used in the UI for reclassification, so it can only include types
// that are being shown in the UI.
export const PersonCompanyRelationshipLabels = {
  [PersonCompanyRelationship.WorkedAt]: "Employment",
  [PersonCompanyRelationship.InvestedIn]: "Investment",
  // This is currently shown in the UI as "employment" so we do not
  // show it as a separate entity.
  // [PersonCompanyRelationship.Founded]: "Founded",
  [PersonCompanyRelationship.VolunteeredAt]: "Volunteering",
  [PersonCompanyRelationship.EducatedAt]: "Education",
  // Not currently included in the UI
  // [PersonCompanyRelationship.Advised]: "Advised",
  // [PersonCompanyRelationship.OtherExperience]: "Other",
} as const;

// relationships are not bi-directional

export const CompanyPersonRelationship = {
  Employee: "employee",
  Founder: "founder",
  Advisor: "advisor",
  BoardMember: "board-member",
  Investor: "investor",
} as const;

export const PersonPersonRelationship = {
  ConnectedTo: "connected-to",
  FriendOf: "friend-of",
} as const;

export const CompanyCompanyRelationship = {
  AcquiredBy: "acquired-by",
  ParentOf: "parent-of",
  SubsidiaryOf: "subsidiary-of",
  RaisedFrom: "raised-from",
  InvestedIn: "invested-in",
} as const;

const RelationshipType = {
  ...PersonCompanyRelationship,
  ...CompanyPersonRelationship,
  ...PersonPersonRelationship,
  ...CompanyCompanyRelationship,
} as const;

export type RelationshipType = (typeof RelationshipType)[keyof typeof RelationshipType];

export type BaseRelationshipData = {
  description?: string;
  order?: number; // order for items of equivalent sort (larger means more recent)
};

export type RelationshipDataWorkedAt = BaseRelationshipData & {
  title?: string;
  location?: string;
  type?: string;
};

export type RelationshipDataEducatedAt = BaseRelationshipData & {
  degree?: string;
  location?: string;
};

export type RelationshipDataVolunteeredAt = BaseRelationshipData & {
  role?: string;
  industry?: string;
};

export type RelationshipDataInvestedIn = {
  fundingRound?: string;
  leadInvestor?: boolean;
  date?: string;
};

type DefaultRelationshipData = {
  [Property in RelationshipType]: BaseRelationshipData;
};

export type RelationshipData = DefaultRelationshipData & {
  [PersonCompanyRelationship.WorkedAt]: RelationshipDataWorkedAt;
  [PersonCompanyRelationship.EducatedAt]: RelationshipDataEducatedAt;
  [PersonCompanyRelationship.VolunteeredAt]: RelationshipDataVolunteeredAt;
  [PersonCompanyRelationship.InvestedIn]: RelationshipDataInvestedIn;
};

export type RelationshipWithEntity = Relationship & {
  to?: Entity | undefined | null;
  from?: Entity | undefined | null;
};

/** a partial relationship that can be used to create a new relationship */
export type ProtoRelationship<T extends RelationshipType> = {
  toEntityUrl?: string;
  toEntityId?: string;
  toEntityName: string;
  toEntityType: EntityType;
  toEntityImageUrl?: string;

  key: string;
  source: RelationshipSource;
  type: T;
  data: RelationshipData[T];

  // YYYY or YYYY-MM or YYYY-MM-DD
  startedDate?: string;
  endedDate?: string;
  deleted?: boolean;
};
