import { RecentActivity } from "@/types/attributes";
import { TweetV2 } from "twitter-api-v2";
export interface SocialAccount {
  service: string;
  url: string;
  username: string;
  connections?: number;
  following?: number;
  followers?: number;
  recentPostCount?: number;
  posts?: SocialPosts["posts"];
}

export type BaseSocialPost = {
  id: string;
  socialAccountId: string;
  following?: number;
  followers?: number;
  recentPostCount?: number;
  lastPostAt: Date | null;
  lastPostId: string | null;
  createdAt: Date;
  updatedAt: Date;
};
export type SocialPosts = BaseSocialPost & TwitterPosts;

export const SocialServices = {
  Twitter: "twitter",
  LinkedIn: "linkedin",
} as const;
export type SocialServices = (typeof SocialServices)[keyof typeof SocialServices];

type TwitterPosts = {
  service: "twitter";
  posts: TweetV2[];
};

export enum LinkedInPostType {
  Text = "text",
  TextWithLink = "text-with-link",
  Link = "link",
  RepostText = "repost-text",
  RepostLink = "repost-link",
  Comment = "comment",
  Unknown = "unknown",
}

export type LinkedInPost = {
  type: LinkedInPostType;
  link?: string;
} & RecentActivity;
