import "@/lib/errorTracker";
import "@/styles/globals.css";
import "react-tooltip/dist/react-tooltip.css";

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/700.css";

import { SessionProvider } from "next-auth/react";
import { useEffect } from "react";

import trackerWeb from "@/client/trackerWeb";
import ErrorBoundary from "@/components/ui/ErrorBoundary";

import { logger } from "@/lib/logger";
import type { Session } from "next-auth";
import type { AppProps } from "next/app";
import RouteTransition from "@/components/ui/RouteTransition";

const LOG_LEVEL =
  process.env.NEXT_PUBLIC_LOG_LEVEL || (process.env.NODE_ENV == "production" ? "info" : "debug");
logger.setLevelString(LOG_LEVEL);

// const newsreader = Newsreader({ subsets: ["latin"], weight: ["400"] });

// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.
export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  useEffect(() => {
    trackerWeb.init();
  }, []);

  return (
    <ErrorBoundary>
      <RouteTransition>
        <SessionProvider session={session}>
          <Component {...pageProps} />
        </SessionProvider>
      </RouteTransition>
    </ErrorBoundary>
  );
}

if (process.env.NODE_ENV == "development") {
  // debugging shortcut
  global.window?.window.addEventListener("keydown", function (event) {
    if (event.key == "F6") {
      debugger;
    }
  });
}

// declare global {
//   interface Window {
//     ethereum: {};
//   }
// }

// if (process.env.NODE_ENV == "development" && global.window) {
//   // for brave browser
//   global.window.ethereum = {};
// }
