import { unwrapError } from "@/lib/miscUtils";

const LS_LOGLEVEL = "loglevel";

export enum Level {
  TRACE = 0,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  OFF,
}

const LevelMap: Record<string, Level> = {
  trace: Level.TRACE,
  debug: Level.DEBUG,
  info: Level.INFO,
  warn: Level.WARN,
  error: Level.ERROR,
};

export const LevelLabels: Record<number, string> = {
  [Level.TRACE]: "trace",
  [Level.DEBUG]: "debug",
  [Level.INFO]: "info",
  [Level.WARN]: "warn",
  [Level.ERROR]: "error",
};

export type LogListener = (level: Level, ...args: unknown[]) => void;

const ConsoleMethodMap: Record<number, (...args: unknown[]) => void> = {
  [Level.TRACE]: console.trace,
  [Level.DEBUG]: console.debug,
  [Level.INFO]: console.info,
  [Level.WARN]: console.warn,
  [Level.ERROR]: console.error,
};

const ConsoleLogger: LogListener = (level: Level, ...args: unknown[]) => {
  ConsoleMethodMap[level](...args);
};

const formatJSON = (args: unknown[]) => {
  const formattedJson = {};
  const message: string[] = [];
  args.forEach((arg) => {
    if (arg instanceof Error) {
      message.push(unwrapError(arg));
    } else if (arg && typeof arg === "object" && !Array.isArray(arg)) {
      Object.assign(formattedJson, arg);
      const msgArg = arg as { message: string };
      if (msgArg.message) {
        message.push(msgArg.message);
      }
    } else {
      message.push(String(arg));
    }
  });
  return message.length ? { message, ...formattedJson } : formattedJson;
};

const JSONLogger: LogListener = (level: Level, ...args: unknown[]) => {
  const formattedMessage = JSON.stringify(formatJSON(args));
  ConsoleMethodMap[level](formattedMessage);
};

const isNode = typeof localStorage == "undefined";

class Logger {
  level: Level;
  logListeners: LogListener[] = [
    process.env.NODE_ENV !== "development" && isNode ? JSONLogger : ConsoleLogger,
  ];
  didNotLogListeners: LogListener[] = [];
  allLogListeners: LogListener[] = [];

  constructor() {
    const defaultLevel =
      process.env.LOG_LEVEL ? LevelMap[process.env.LOG_LEVEL]
      : process.env.NODE_ENV == "development" || isNode ? Level.INFO
      : Level.ERROR;
    try {
      const storedLogLevel = !isNode && localStorage.getItem(LS_LOGLEVEL);
      const level = storedLogLevel && LevelMap[storedLogLevel];
      this.level = level || defaultLevel;
    } catch (e) {
      this.level = defaultLevel;
    }
  }

  setLevel = (level: Level) => (this.level = level);

  setLevelString = (level: string) => (this.level = LevelMap[level]);

  log(level: Level, ...args: unknown[]) {
    this.allLogListeners.forEach((l) => {
      l(level, ...args);
    });
    if (level < this.level) {
      this.didNotLogListeners.forEach((l) => {
        l(level, ...args);
      });
      return;
    }
    this.logListeners.forEach((l) => {
      l(level, ...args);
    });
  }

  saveDefaultLogLevel(level: string) {
    localStorage.setItem(LS_LOGLEVEL, level);
    this.setLevelString(level);
  }

  trace = (...args: unknown[]) => {
    this.log(Level.TRACE, ...args);
  };
  debug = (...args: unknown[]) => {
    this.log(Level.DEBUG, ...args);
  };
  info = (...args: unknown[]) => {
    this.log(Level.INFO, ...args);
  };
  warn = (...args: unknown[]) => {
    this.log(Level.WARN, ...args);
  };
  error = (...args: unknown[]) => {
    this.log(Level.ERROR, ...args);
  };

  withPrefix = (...prefix: string[]) => {
    return {
      setLevel: (level: Level) => {
        this.setLevel(level);
      },
      log: (level: Level, ...args: unknown[]) => {
        this.log(level, ...prefix, ...args);
      },
      trace: (...args: unknown[]) => {
        this.log(Level.TRACE, ...prefix, ...args);
      },
      debug: (...args: unknown[]) => {
        this.log(Level.DEBUG, ...prefix, ...args);
      },
      info: (...args: unknown[]) => {
        this.log(Level.INFO, ...prefix, ...args);
      },
      warn: (...args: unknown[]) => {
        this.log(Level.WARN, ...prefix, ...args);
      },
      error: (...args: unknown[]) => {
        this.log(Level.ERROR, ...prefix, ...args);
      },
    };
  };
}

const instance = new Logger();

export const logger = instance;

export const loggerWithPrefix = instance.withPrefix;
