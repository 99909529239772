import { linkedinCompanySummary } from "@/parsers/linkedinCompanyParser";
import { linkedinProfileSummary } from "@/parsers/linkedinParser";
import {
  EntityType,
  EntityWithAttributes,
  PartialEntity,
  PersonAttributes,
  UserWithMeta,
  isLinkedinCompanyProfile,
  isLinkedinPersonProfile,
  userMeta,
} from "@/types";
import { Entity, User } from "@prisma/client";
import { JsonValue } from "@prisma/client/runtime/library";

export function objToString(json: JsonValue | object, nested = false): string {
  const result: string[] = [];

  if (typeof json == "string") return json;

  if (!json || typeof json != "object") return JSON.stringify(json);

  const possibleProfile = json as PersonAttributes;
  const possibleWorkExperience = json as PersonAttributes["workExperiences"][0];

  // Handle the 'name' key if it exists
  if (possibleProfile.name) {
    result.push(possibleProfile.name);
  }

  if (possibleWorkExperience.startDate) {
    result.push(`${possibleWorkExperience.startDate} - ${possibleWorkExperience.endDate || ""}`);
  }

  const obj = json as Record<string, object>;

  const regularKeys = Object.keys(obj).filter(
    (key) => key != "name" && key != "startDate" && key != "endDate" && typeof obj[key] != "object",
  );

  if (nested) {
    result.push(...regularKeys.map((key) => String(obj[key])));
  } else {
    result.push(...regularKeys.map((key) => `${key}: ${String(obj[key])}`));
  }

  // Handle array keys
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (!value) return;
    if (Array.isArray(value)) {
      result.push(`${key}:`);
      value.forEach((item, index) =>
        result.push("- " + objToString(item as object, true).replace(/\n/g, "\n  ")),
      );
    } else if (typeof value == "object") {
      result.push(`${key}:`);
      result.push(objToString(value, true).replace(/\n/g, "\n  "));
    }
  });

  return result.join(nested ? ", " : "\n");
}

export function entityDescription(entity: EntityWithAttributes): string {
  return (
    entity.type == EntityType.Person && isLinkedinPersonProfile(entity.attributes?.[0]?.value) ?
      linkedinProfileSummary(entity.attributes[0].value)
    : entity.type == EntityType.Company && isLinkedinCompanyProfile(entity.attributes?.[0]?.value) ?
      linkedinCompanySummary(entity.attributes[0].value)
    : entity.attributes && Object.keys(entity.attributes).length > 1 ?
      objToString({ url: entity.url, description: entity.description, ...entity.attributes })
    : entity.description || entity.type
  );
}

export const entityUrl = (entity: PartialEntity | undefined) => {
  if (entity?.slug) {
    return entity.slug;
  }
  if (entity?.id) {
    return `/entity/${entity.id}`;
  }
  return "#";
};

export const entityIsUser = (entity: { id: string; url: string }, user: UserWithMeta | User) => {
  const meta = userMeta(user);
  return (
    (entity.id && meta.entity_id == entity.id) ||
    entity.url?.includes(`linkedin.com/in/${meta.li_profile}`)
  );
};
