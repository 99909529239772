import { slugify } from "@/lib/utils";
import { Entity, EntityWithAttributes, isEntity } from "@/types/db";
import { EmailIdentity } from "@/types/emails";
import { GenericProfile, isSnapshot } from "@/types/snapshots";
import Prisma from "@prisma/client";

export type ListOverview = Omit<Prisma.List, "customFields" | "deletedAt">;

export function isListOverview(u: unknown): u is ListOverview {
  if (typeof u !== "object" || u == null) return false;
  const { id, name, updatedAt, lastPublishedAt } = u as Partial<ListOverview>;
  return (
    typeof id === "string" &&
    typeof name === "string" &&
    updatedAt instanceof Date &&
    (lastPublishedAt == null || lastPublishedAt instanceof Date)
  );
}

export type ListDetails = Prisma.List & {
  users: ListUser[];
  views: ListView[];
};

export function isListDetails(u: unknown): u is ListDetails {
  if (typeof u !== "object" || u == null) return false;
  const { id, name, updatedAt, users, views, customFields } = u as Partial<ListDetails>;
  return (
    typeof id === "string" &&
    typeof name === "string" &&
    updatedAt instanceof Date &&
    Array.isArray(customFields) &&
    customFields.every(isListCustomFieldDefinition) &&
    Array.isArray(users) &&
    users.every(isListUser) &&
    Array.isArray(views) &&
    views.every(isListView)
  );
}

export type ListCustomFieldType = "text";

export function isListCustomFieldType(u: unknown): u is ListCustomFieldType {
  return typeof u === "string" && (["text"] as readonly string[]).includes(u);
}

export type ListCustomFieldDefinition = {
  id: string;
  name: string;
  type: ListCustomFieldType;
};

export function isListCustomFieldDefinition(u: unknown): u is ListCustomFieldDefinition {
  if (typeof u !== "object" || u == null) return false;
  const { id, name, type } = u as Partial<ListCustomFieldDefinition>;
  return typeof id === "string" && typeof name === "string" && isListCustomFieldType(type);
}

export type ListCustomFieldData = { type: ListCustomFieldType; value: string };

export function isListCustomFieldData(u: unknown): u is ListCustomFieldData {
  if (typeof u !== "object" || u == null) return false;
  const { type, value } = u as Partial<ListCustomFieldData>;
  return isListCustomFieldType(type) && typeof value === "string";
}

export type ListCustomFieldValue = Omit<Prisma.ListCustomFieldValue, "data"> & {
  data: ListCustomFieldData;
};

export function isListCustomFieldValue(u: unknown): u is ListCustomFieldValue {
  if (typeof u !== "object" || u == null) return false;
  const { id, fieldId, entryId, data } = u as Partial<ListCustomFieldValue>;
  return (
    typeof id === "string" &&
    typeof fieldId === "string" &&
    typeof entryId === "string" &&
    isListCustomFieldData(data)
  );
}

const listUserRoles = ["owner", "member"] as const;

export type ListUserRole = (typeof listUserRoles)[number];

export function isListUserRole(u: unknown): u is ListUserRole {
  return typeof u === "string" && (listUserRoles as readonly string[]).includes(u);
}

export type ListUser = Omit<Prisma.ListUser, "role"> & { role: ListUserRole };

export function isListUser(u: unknown): u is ListUser {
  if (typeof u !== "object" || u == null) return false;
  const { id, listId, userId, role } = u as Partial<ListUser>;
  return (
    typeof id === "string" &&
    typeof listId === "string" &&
    typeof userId === "string" &&
    isListUserRole(role)
  );
}

const listEntryTypes = ["entity", "section", "divider"] as const;
export const DEFAULT_LIST_ENTRY_GAP = 1000;

export type ListEntryType = (typeof listEntryTypes)[number];

export function isListEntryType(u: unknown): u is ListEntryType {
  return typeof u === "string" && (listEntryTypes as readonly string[]).includes(u);
}

export type ListEntryData = ListEntryEntityData | ListEntryComponentData;

export type ListEntryEntityData = { url?: string; email?: string; name: string };

export type ListEntryComponentData = {
  title?: string;
  description?: string;
};

export type ListEntrySpec = {
  customFields?: ListCustomFieldSpec[];
  data?: ListEntryData;
  entityId?: string;
  order?: number;
  type: ListEntryType;
} & (
  | {
      type: "section";
      data?: ListEntryComponentData;
    }
  | {
      type: "divider";
      data?: ListEntryComponentData;
    }
  | {
      type: "entity";
      data: ListEntryEntityData;
    }
);

export function isListEntrySpec(u: unknown): u is ListEntrySpec {
  if (typeof u !== "object" || u == null) return false;
  const { type, data, customFields } = u as Partial<ListEntrySpec>;

  if (!isListEntryType(type)) return false;
  if (data && typeof data !== "object") return false;

  if (
    customFields &&
    (!Array.isArray(customFields) || !customFields.every(isListCustomFieldSpec))
  ) {
    return false;
  }

  return true;
}

export type ListEntry = Omit<Prisma.ListEntry, "type" | "data"> & {
  type: ListEntryType;
  data?: ListEntryData;
};

export function isListEntry(u: unknown): u is ListEntry {
  if (typeof u !== "object" || u == null) return false;
  const { id, listId, entityId } = u as Partial<ListEntry>;
  return (
    typeof id === "string" &&
    typeof listId === "string" &&
    (entityId == null || typeof entityId === "string")
  );
}

export type ListEntryDetails = ListEntry & {
  entity?: EntityWithAttributes;
  customFields: ListCustomFieldValue[];
};

export type ListEntrySnapshot = ListEntry & {
  snapshot: GenericProfile;
  customFields: ListCustomFieldValue[];
};

export function isListEntryDetails(u: unknown): u is ListEntryDetails {
  if (!isListEntry(u)) return false;
  const { entity, customFields } = u as Partial<ListEntryDetails>;
  return (
    (entity == null || isEntity(entity)) &&
    Array.isArray(customFields) &&
    customFields.every(isListCustomFieldValue)
  );
}

export function isListEntrySnapshot(u: unknown): u is ListEntrySnapshot {
  if (!isListEntry(u)) return false;
  const { snapshot, customFields } = u as Partial<ListEntrySnapshot>;
  return (
    (snapshot == null || isSnapshot(snapshot)) &&
    Array.isArray(customFields) &&
    customFields.every(isListCustomFieldValue)
  );
}

export type ListCustomFieldSpec = {
  fieldId: string;
  data: ListCustomFieldData | "delete";
};

export function isListCustomFieldSpec(u: unknown): u is ListCustomFieldSpec {
  if (typeof u !== "object" || u == null) return false;
  const { fieldId, data } = u as Partial<ListCustomFieldSpec>;
  return typeof fieldId === "string" && (data === "delete" || isListCustomFieldData(data));
}

const listViewFieldSelectionTypes = ["entity", "email", "custom"] as const;

export type ListViewFieldSelectionType = (typeof listViewFieldSelectionTypes)[number];

export type ListViewFieldSelection = { type: ListViewFieldSelectionType } & (
  | {
      type: "entity";
      property: string;
    }
  | {
      type: "email";
    }
  | {
      type: "custom";
      id: string;
    }
);

export function isListViewFieldSelection(u: unknown): u is ListViewFieldSelection {
  if (typeof u !== "object" || u == null) return false;
  const { type } = u as Partial<ListViewFieldSelection>;
  return (
    typeof type === "string" && (listViewFieldSelectionTypes as readonly string[]).includes(type)
  );
}

const listViewDisplayModes = ["cards"] as const;

export type ListViewDisplayMode = (typeof listViewDisplayModes)[number];

export function isListViewDisplayMode(u: unknown): u is ListViewDisplayMode {
  return typeof u === "string" && (listViewDisplayModes as readonly string[]).includes(u);
}

export type ListView = Omit<Prisma.ListView, "fields" | "displayMode"> & {
  displayMode: ListViewDisplayMode;
  fields: ListViewFieldSelection[];
  list: ListOverview;
};

export function isListView(u: unknown): u is ListView {
  if (typeof u !== "object" || u == null) return false;
  const { id, listId, displayMode, fields } = u as Partial<ListView>;
  return (
    typeof id === "string" &&
    typeof listId === "string" &&
    isListViewDisplayMode(displayMode) &&
    Array.isArray(fields) &&
    fields.every(isListViewFieldSelection)
  );
}

export type ListEntryResolution = {
  spec: ListEntrySpec;
  identity?: EmailIdentity;
  entity?: Entity;
  order?: number;
};

export const ListRoutes = {
  list: (list: ListOverview | ListDetails) => `/lists/${list.id}`,

  view: (view: ListView, listName?: string) => {
    const path = `/list/${view.code}`;
    return listName ? `${path}/${slugify(listName)}` : path;
  },
} as const;
