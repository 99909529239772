import axios, { AxiosInstance, CreateAxiosDefaults } from "axios";

import type { StreamListener } from "@/client/apiTypes";
import type { HatchetWorkflow, HatchetWorkflowInputs } from "@/hatchet/hatchetTypes";
import { logger } from "@/lib/logger";
import {
  AutocompleteEntity,
  CompanyCheckResponse,
  CookieMeta,
  CrawlResultSansBody,
  DogfoodFeedback,
  EmailEntryPoint,
  EmailSearchResult,
  Entity,
  EntityType,
  EntityWithAttributes,
  FullDetectorOutput,
  GenericProfile,
  ImageDetails,
  InterruptiblePromise,
  LinkedinSearchResults,
  LinkWithDescription,
  ListDetails,
  ListView,
  Meeting,
  MeetingResponse,
  MutualConnectionsResponse,
  OpenAIModel,
  PartialEntity,
  ProfileFeedback,
  ProfileProblemCore,
  ProfileSection,
  SocialPosts,
  SocialServices,
  SuccessResponse,
  User,
  UserMeta,
  ValueOf,
  WorkplaceResolveRequest,
  WorkplaceResolveResponse,
} from "@/types";
import {
  EntityFilter,
  EntitySearchQuery,
  EntitySource,
  EntitySourceUpdate,
  Fact,
  FeedbackCategory,
  Invite,
  OAuthToken,
  ProfileProblem,
  Relationship,
  UserProfile,
} from "@prisma/client";

import { ListEntriesResource, ListsResource } from "@/client/lists";
import {
  deleteRoute,
  getRoute,
  postRoute,
  putRoute,
  Resource,
  ResourceWithParent,
  SingleResource,
} from "@/client/resource";
import errorTracker from "@/lib/errorTracker";
import { RelationshipWithEntity } from "@/models/relationship/relationshipTypes";
import {
  ResolveEmailIdentityRequest,
  ResolveEmailIdentityResponse,
} from "@/pages/api/emails/resolveEmailIdentities";
import { SummarizedResult } from "@/utils/summarizeSelectedSources";

type EmailResolveRequest = {
  email: string;
  name?: string;
  meetingIds: string[];
};

type EmailResolveResponse = {
  results: {
    email: string;
    meetingIds: string[];
    entity?: EntityWithAttributes;
    resolutions?: EmailSearchResult[];
    error?: string;
  }[];
  errors?: string[];
};

class APIService {
  axios: AxiosInstance;

  endpoint: string;

  constructor() {
    this.endpoint = "/api";
    const config: CreateAxiosDefaults = {
      baseURL: this.endpoint,
    };
    this.axios = axios.create(config);

    // instrument axios calls + api calls
    axios.interceptors.request.use((config) => {
      errorTracker.addBreadcrumb({
        action: "axios call",
        message: `${config.method?.toUpperCase()} ${config.url}`,
        category: "axios",
        metadata: { params: config.params ? JSON.stringify(config.params) : undefined },
      });
      return config;
    });
    this.axios.interceptors.request.use((config) => {
      errorTracker.addBreadcrumb({
        action: "api call",
        message: `${config.method?.toUpperCase()} ${config.url}`,
        category: "api",
        metadata: { params: config.params ? JSON.stringify(config.params) : undefined },
      });
      return config;
    });
  }

  // --- user management

  user = new SingleResource<User>(this, "users");

  userProfile = new SingleResource<UserProfile>(this, "users/profile");

  updateUserMeta = postRoute<{ meta: UserMeta }, UserMeta>(this, `/users/meta`);

  updateCookie = postRoute<{ url: string; name: string; value: string; meta?: CookieMeta }>(
    this,
    `/users/updateCookie`,
  );

  runOnboarding = postRoute<{ cookieToSet: string }, string>(this, `/users/LinkedInOnboarding`);

  // --- entity resources

  sources = new Resource<EntitySource>(this, "sources");

  getDogfoodFeedbacks = getRoute<{ entityId?: string; createdToday?: boolean }, ProfileFeedback[]>(
    this,
    `/dogfoodFeedback`,
  );
  createDogfoodFeedback = postRoute<{
    entityId: string;
    userId: string;
    response?: object;
    feedbackCategoryIds?: string[];
  }>(this, `/dogfoodFeedback`);

  dogfoodFeedbacks = new Resource<DogfoodFeedback>(this, "dogfoodFeedback");
  feedbackCategories = new Resource<FeedbackCategory>(this, "dogfoodFeedback/categories");

  profileProblems = new Resource<
    ProfileProblem & { feedbackId?: string; unlinkFeedbackId?: string }
  >(this, "profileProblems");

  testProfileProblem = postRoute<
    {
      entityIds: string[];
      problem: ProfileProblemCore;
    },
    FullDetectorOutput[]
  >(this, "/profileProblems/test");

  getSources = getRoute<
    {
      entityId: string;
      sourceId?: string;
      userId?: string;
    },
    {
      sources: EntitySource[];
      scraped: CrawlResultSansBody[];
      images: ImageDetails[];
      sourceUpdates: EntitySourceUpdate[];
    }
  >(this, `/sources/load`);

  getSections = getRoute<{ entityId: string }, ProfileSection[]>(this, `/sections`);

  deleteSections = deleteRoute<{ entityId: string }, ProfileSection[]>(this, `/sections`);

  facts = new ResourceWithParent<Entity, Fact>(this, "entityId", "facts");

  getFilter = getRoute<{ entityId: string }, EntityFilter>(this, `/filters`);

  getQuery = getRoute<{ entityId: string }, EntitySearchQuery>(this, `/queries`);

  deleteEntity = deleteRoute<{ entityId: string }, { redirect: string }>(this, `/entities/delete`);

  mergeEntities = postRoute<{ fromEntityId: string; toEntityUrl: string }, { redirect: string }>(
    this,
    `/entities/merge`,
  );

  pronounsUpdated = postRoute<{ entityId: string; pronouns: string }>(
    this,
    `/entities/pronounsUpdated`,
  );

  getMutualConnections = getRoute<{ entityId: string }, MutualConnectionsResponse>(
    this,
    `/mutualConnections`,
  );

  // --- search & create entities

  searchEntities = getRoute<{ query: string; page?: number }, PartialEntity[]>(this, `/entities`);

  searchEntitiesFull = getRoute<{ query: string; page?: number }, Entity[]>(
    this,
    `/search/entities`,
  );

  searchWeb = getRoute<{ q: string; page?: number }, LinkWithDescription[]>(this, `/search`);

  searchScraped = postRoute<
    { url: string; html: string },
    LinkWithDescription[] | LinkedinSearchResults
  >(this, `/search/scraped`);

  searchLi = getRoute<
    { q: string; type: "all" | "people" | "companies"; page?: number; html?: string },
    LinkedinSearchResults
  >(this, `/search/li`);

  companyCheck = postRoute<
    { url: string; title: string; description: string },
    CompanyCheckResponse
  >(this, `/entities/companyCheck`);

  autocomplete = getRoute<
    { query: string; page?: number; type?: EntityType },
    AutocompleteEntity[]
  >(this, `/search/autocomplete`);

  createEntity = postRoute<
    {
      url: string;
      name?: string;
      html?: string;
      fallback?: boolean;
      loadFull?: boolean;
      type?: EntityType;
    },
    Entity
  >(this, `/entities/create`);

  resolveEntity = getRoute<
    { query: string },
    { entity: EntityWithAttributes; snapshot: GenericProfile } | null
  >(this, `/entities/resolve`);

  getSnapshot = getRoute<{ entityId: string }, GenericProfile>(this, `/snapshots`);
  getSnapshots = getRoute<{ entityId: string[] }, GenericProfile[]>(this, `/snapshots`);

  resolveAllEntities = postRoute<{ queries: string[] }, Record<string, EntityWithAttributes>>(
    this,
    `/entities/resolveAll`,
  );

  entities = new Resource<
    Entity,
    Entity[],
    Entity,
    { query?: string; ids?: string[]; page?: number; limit?: number }
  >(this, "entities");

  entityFromDomain = postRoute<
    { requests: WorkplaceResolveRequest[]; resolveMissing?: boolean },
    WorkplaceResolveResponse
  >(this, `/entities/entityFromDomain`);

  // --- meetings

  meetings = new Resource<Meeting, Meeting[], MeetingResponse>(this, "meetings");

  meetingsUpdateNotes = postRoute<{ meetingId: string; notes: string }>(this, `/meetings/notes`);

  // --- calendars

  listCalendars = getRoute<{}, OAuthToken[]>(this, `/calendars/list`);

  getConnectUrl = getRoute<{ type: string; state?: string }, string>(this, `/calendars/connect`);

  syncCalendars = postRoute<{ date?: string }, Meeting[]>(this, `/calendars/sync`);

  deleteToken = deleteRoute<{ tokenId: string }>(this, `/oauth/delete`);

  // --- emails

  emailTokenList = getRoute<{}, OAuthToken[]>(this, `/emails/list`);

  emailWarmup = getRoute<{ email?: string }, SuccessResponse>(this, `/emails/warmup`);

  emailContactSearch = postRoute<
    { calendarId: string; emails: string[]; cache?: boolean },
    Record<string, string>
  >(this, `/emails/contactSearch`);

  emailResolve = postRoute<EmailResolveRequest[], EmailResolveResponse>(this, `/emails/resolve`);

  emailConfirm = postRoute<{
    email: string;
    confirmed: boolean;
    entityId: string;
    meetingId?: string;
    entryPoint?: EmailEntryPoint;
  }>(this, `/emails/confirm`);

  emailIgnore = postRoute<{ email: string }>(this, `/emails/ignore`);

  resolveEmailIdentities = postRoute<ResolveEmailIdentityRequest, ResolveEmailIdentityResponse>(
    this,
    `/emails/resolveEmailIdentities`,
  );

  // --- entity actions

  // This triggers a whole update workflow.
  // This should NOT be used to modify the entity itself.
  triggerEntityRefresh = postRoute<{ entityId: string }>(this, `/entities/update`);

  sourcesRegenerate = postRoute<{ entityId: string }>(this, `/sources/regenerate`);

  summarizeSource = postRoute<{ entityId: string; sourceId: string }, SummarizedResult>(
    this,
    `/sources/summarize`,
  );

  sourcesClearAll = postRoute<{ entityId: string }>(this, `/sources/clearAll`);

  // --- relationships

  relationships = new Resource<Relationship, RelationshipWithEntity[]>(this, "relationships");
  batchDeleteRelationships = deleteRoute<
    { entityId: string; toId: string | undefined; toName: string | undefined },
    boolean
  >(this, "/relationships/batch");
  batchUpdateRelationships = putRoute<
    Partial<Relationship>,
    {
      entityId: string;
      toId?: string;
      toName?: string;
    },
    { count: number }
  >(this, "/relationships/batch");

  relationshipsTo = getRoute<{ entityId: string }, RelationshipWithEntity[]>(
    this,
    `/relationships/to`,
  );

  // --- workflows

  triggerHatchetEvent = postRoute<
    { event: HatchetWorkflow; input: Partial<ValueOf<HatchetWorkflowInputs>> },
    string
  >(this, `/hatchet/trigger`);

  // --- playground

  calculateTokens = postRoute<{ input: string }, number>(this, `/playground/tokens`);

  playgroundResponse = (
    input: string,
    model: OpenAIModel,
    cb: (message: string | object) => void,
  ): InterruptiblePromise => {
    return this.stream(`/playground/stream`, { input, model }, cb);
  };

  // --- invites

  invites = new Resource<Invite>(this, "invites");

  // --- streaming logic

  stream = (url: string, body: unknown, onMessage: StreamListener): InterruptiblePromise => {
    const controller = new AbortController();
    const signal = controller.signal;

    // use fetch since this use of axios doesn't support streaming
    const promise = new Promise<void>((resolve, reject) => {
      fetch(this.endpoint + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        signal,
      })
        .then((response) => {
          if (!response.body) {
            reject(new Error("No response body"));
            return;
          }

          // Create a ReadableStream from the response body
          const reader = response.body.getReader();

          // Define a function to read the stream
          let previousText = "";
          function readStream(): Promise<void> {
            return reader.read().then(({ done, value }) => {
              if (done) {
                resolve();
                return;
              }

              const text = new TextDecoder().decode(value);
              try {
                const toParse = previousText + text;
                const messages = parsePartialMessages(toParse);
                messages.forEach((m) => {
                  onMessage(m);
                });
                previousText = "";
              } catch (error) {
                if (text.length > 2000) {
                  // this can happen because of a very long response
                  previousText += text;
                  return readStream();
                }
                logger.error("decoding and parsing", text, error);
                reject(error instanceof Error ? error : new Error(String(error)));
              }

              // Continue reading the stream
              return readStream();
            });
          }

          // Start reading the stream
          return readStream();
        })
        .catch((error: unknown) => {
          if (String(error).includes("aborted")) return;
          logger.info("chat error", error);
          reject(error instanceof Error ? error : new Error(String(error)));
        });
    });

    return {
      interrupt: () => {
        try {
          controller.abort();
        } catch (e) {}
      },
      promise,
    };
  };

  // --- notes
  sourceUpdates = new ResourceWithParent<Entity, EntitySourceUpdate>(
    this,
    "entityId",
    "sourceUpdates",
  );

  // --- social
  fetchSocialPosts = postRoute<{ service: SocialServices; url: string }, SocialPosts | null>(
    this,
    `/socials/posts`,
  );

  // --- support
  emailSupport = postRoute<
    { email: string; message: string; currentTitle: string; currentHref: string },
    string
  >(this, `/support/email`);

  // --- lists
  lists = new ListsResource(this);

  listEntries = new ListEntriesResource(this);

  listViews = new ResourceWithParent<ListDetails, ListView>(this, "listId", "listViews");
}

const API = new APIService();

export default API;

const parsePartialMessages = (text: string): (object | string)[] => {
  try {
    return JSON.parse("[" + (text.endsWith(",") ? text.slice(0, -1) : text) + "]") as (
      | object
      | string
    )[];
  } catch (e) {
    return [text];
  }
};
