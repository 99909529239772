// --- enum types

/* note - we use const enums for entity types stored in the database. This allows us
   to perform type comparisons with a union type rather than an enum. See PR #537 */
export const EntityType = {
  Company: "company",
  Person: "person",
  Unknown: "unknown",
} as const;

export type EntityType = (typeof EntityType)[keyof typeof EntityType];

export enum EntityListType {
  Following = "following",
  Private = "private",
  Public = "public",
}

export const ProfileSections = {
  Overview: "overview",
  History: "history",
  Location: "location",
  WorkHistory: "work",
  CurrentRole: "currentrole",
  Education: "education",
  SocialMedia: "socialmedia",
  Connections: "connections",
  WebLinks: "weblinks",
  CommonalInterests: "common",
} as const;
export type ProfileSections = (typeof ProfileSections)[keyof typeof ProfileSections];

export enum PipelineName {
  EmailToPerson = "email",
  ProfileToPerson = "profile",
  CompanyUrl = "url",
  Entity = "entity",
  RegenerateSummary = "entity-summary",
  PersonCompany = "person-company",

  Meeting = "meeting",
  UpdateMeeting = "update-meeting",
}

export const SourceType = {
  Profile: "profile",
  NewsArticle: "news_article",
  BlogArticle: "blog_article",
  Interview: "interview",
  SocialMedia: "social_media",
  Project: "project",
  ErrorPage: "error",
  Other: "other",
} as const;
export type SourceType = (typeof SourceType)[keyof typeof SourceType];

export const AllSourceTypes: string[] = Object.values(SourceType);

export enum SourceTag {
  Work = "work",
  Personal = "personal",
  Education = "education",
  Investing = "investing",
  Hobbies = "hobbies",
}

export const AllSourceTags: string[] = Object.values(SourceTag);

export enum QuestionType {
  Investing = "investing",
  Pitching = "pitching",
  Hiring = "hiring",
  JobSearching = "jobsearching",
  Selling = "selling",
  Other = "other",
}

export enum AttributeSource {
  LinkedInProfile = "linkedin",
  Legacy = "legacy",
}

export const OAuthTokenType = {
  Calendar: "calendar",
  Email: "email",
} as const;
export type OAuthTokenType = (typeof OAuthTokenType)[keyof typeof OAuthTokenType];

export const FeedbackType = {
  Overview: "overview",
  Sources: "sources",
} as const;
export type FeedbackType = (typeof FeedbackType)[keyof typeof FeedbackType];

export enum SourceIsRight {
  No = 0,
  Unsure = 1,
  Yes = 2,
  Duplicate = 3,
}
export const SourceIsRightText = "0 = wrong, 1 = unsure, 2 = right entity";

export enum SourceImportance {
  Wrong = 0,
  Unimportant = 1,
  Useful = 2,
  Important = 3,
}
export const SourceImportanceText =
  "0 = useless, 1 = unimportant (about the entity, but not useful) or unsure, 2 = relevant (may contain useful info), 3 = important (good info about the entity).";

export enum SourceCat {
  Starred = "starred",
  Relevant = "relevant",
  Unsure = "unsure",
  Unhelpful = "unhelpful",
  Wrong = "wrong",
  Error = "error",
  NotScored = "notScored",
  Duplicate = "duplicate",
  Hidden = "hidden",
}

export enum FeatureFlag {
  BetaUI = "beta-ui",
  SnapshotUI = "snapshot-ui",
}

export enum ProfilePageSection {
  About = "about",
  Highlights = "highlights",
  Stats = "stats",
  ForYou = "for-you",
  WorkHistory = "work-history",
  OtherExperience = "other-experience",
  Investments = "investments",
  Volunteering = "volunteering",
  Education = "education",
  SocialMedia = "social-media",
  PhotosVideos = "photos-videos",
  MediaCoverage = "media-coverage",
  MutualConnections = "mutual-connections",
  Connections = "connections",
  Sources = "sources",
  Funding = "funding",
  People = "people",
}
