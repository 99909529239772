import posthog from "posthog-js";

import { logger as _logger } from "@/lib/logger";

const log = (type: string, color: string, ...toLog: unknown[]) => {
  _logger.info(`%c[tracker] %c${type}`, "color: lightgreen", `color: ${color}`, ...toLog);
};

type CaptureProperties = Record<string, string | number | boolean | null | undefined>;

const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY || "";
const POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com";
const NEXTAUTH_URL = process.env.NEXTAUTH_URL || "localhost:3000";

class TrackerWeb {
  initialized = false;
  skipSend = true;

  init() {
    if (this.initialized) return;
    this.initialized = true;

    this.skipSend =
      process.env.CI || typeof window === "undefined" ?
        true
      : process.env.NODE_ENV == "development";
    const skipSend = this.skipSend;

    log("posthog init", "lightblue", POSTHOG_KEY, POSTHOG_HOST);
    posthog.init(POSTHOG_KEY, {
      // Use this for posting directly to posthog
      // api_host: POSTHOG_HOST,
      // Use this for local reverse proxy
      api_host: "/posthog/",
      ui_host: "https://us.posthog.com",
      opt_in_site_apps: true,
      autocapture: !skipSend,
      disable_session_recording: skipSend,
    });
    if (process.env.GIT_HASH) {
      posthog.register({
        deployment_id: process.env.GIT_HASH,
      });
    }
  }

  debugMode() {
    if (!this.skipSend) posthog.debug(true);
  }

  identify(userId: string, setAttrs: CaptureProperties, setOnceAttrs?: CaptureProperties) {
    if (!this.initialized) {
      trackerWeb.init();
    }
    if (this.skipSend) log("set", "lavender", setAttrs, setOnceAttrs);
    else posthog.identify(userId, setAttrs, setOnceAttrs);
  }

  capture(event: string, properties?: CaptureProperties) {
    if (!this.initialized) {
      trackerWeb.init();
    }
    if (this.skipSend) log(event, "orange", properties);
    else posthog.capture(event, properties);
  }

  logout() {
    if (!this.initialized) return;
    posthog.reset();
  }
}

const trackerWeb = new TrackerWeb();
export default trackerWeb;
