export const PipelineRunStatus = {
  SCHEDULED: "scheduled",
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  PAUSED: "paused",
  COMPLETED: "completed",
  ERROR: "error",
  ABORTED: "aborted",
  TIMED_OUT: "timed_out",
} as const;
export type PipelineRunStatus = (typeof PipelineRunStatus)[keyof typeof PipelineRunStatus];

export enum StageResultStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  ERROR = "error",
  ABORTED = "aborted",
  TIMED_OUT = "timed_out",
}

export interface PipelineProgress {
  step: number;
  label: string;
  stepsLeft: number;
  estimatedSecs: number;
}
