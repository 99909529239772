import { Attribute } from "@/types";
import { EntityWithAttributes, Meeting, UserWithMeta } from "@/types/db";
import Prisma from "@prisma/client";
import { GetServerSidePropsContext, Redirect } from "next";
import { Session } from "next-auth";

export const WAITLIST_URL = "https://forms.gle/1X69TMSqvoSWSCDx5";

export type PartialEntity = {
  id: string;
  name: string;
  type: string;
  url: string;
  slug?: string | null;
};

export type ServerProps = {
  userId?: string;
  user?: UserWithMeta;
  team?: Prisma.Team;
};

export type WorkspaceData = {
  session: Session;
  redirect: { redirect: Redirect } | null;
  cookieFragments?: CookieFragments;
} & ServerProps;

export type CookieFragments = { [domain: string]: string[] };

export type CookieStatus = "valid" | "invalid" | "missing";

export interface WorkspaceContext extends GetServerSidePropsContext {
  workspaceData: WorkspaceData;
}

export type SuccessResponse = {
  success: boolean;
};

export type ErrorResponse = {
  error: string;
};

export type InterruptiblePromise = { promise: Promise<void>; interrupt: () => void };

export type MeetingResponse = {
  id: string;
  meeting: Meeting;
  profile: Prisma.MeetingProfile | null;
  sections: Prisma.MeetingSection[];
  entities: Prisma.Entity[];
  attributes: Attribute[];
};

export function isMeeting(l: unknown): l is Meeting {
  const meetingCheck =
    (l as Meeting)?.id !== undefined &&
    (l as Meeting)?.title !== undefined &&
    (l as Meeting)?.start !== undefined &&
    (l as Meeting)?.calendarId !== undefined;

  return meetingCheck;
}

export type WorkplaceResolveRequest = { domain: string; personId: string };

export type WorkplaceResolveResponse = {
  results: (WorkplaceResolveRequest & { entityId?: string; needsResolving?: boolean })[];
  entities: Record<string, EntityWithAttributes>;
};

export type CompanyCheckResponse = {
  linkedinUrl?: string;
  aiCompanyClassification?: boolean;
};

export type PagingOptions = {
  page: number;
  pageSize?: number;
};

export type PagingResult<T> = {
  result: T;
  total: number;
  page: number;
};
