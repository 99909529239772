import { toEmailParts } from "@/lib/utils";
import { AnalyticsEvent, isAnalyticsEvent } from "@/types/analytics";
import { LinkedinSearchResult } from "@/types/attributes";
import { LinkWithDescription } from "@/types/crawlerType";
import { Entity } from "@/types/db";

export type EmailContact = {
  address: string;
  name?: string;
};

export function isEmailContact(u: unknown): u is EmailContact {
  return (
    typeof u === "object" &&
    u !== null &&
    "address" in u &&
    typeof (u as { address: unknown }).address === "string" &&
    !!toEmailParts((u as { address: string }).address).address
  );
}

export type EmailParts = EmailContact & {
  input: string;
  domain: string;
  local: string;
};

export type EmailSearchResult = LinkWithDescription | LinkedinSearchResult;

const emailEntryPoints = ["gmail", "gmailPoll", "dashboard", "manual", "lists"] as const;
export type EmailEntryPoint = (typeof emailEntryPoints)[number];

export function isEmailEntryPoint(u: unknown): u is EmailEntryPoint {
  return typeof u === "string" && (emailEntryPoints as readonly string[]).includes(u);
}

export function isGmailEntryPoint(u: unknown): u is EmailEntryPoint {
  return u === "gmail" || u === "gmailPoll";
}

const emailLookupSources = [
  "ai",
  "tables",
  "mixrank",
  "find",
  "crawl",
  "existing",
  "domain",
] as const;
export type EmailLookupSource = (typeof emailLookupSources)[number];

export function isEmailLookupSource(u: unknown): u is EmailLookupSource {
  return typeof u === "string" && (emailLookupSources as readonly string[]).includes(u);
}

export enum EmailResolutionStatus {
  CANDIDATE = "candidate",
  ERROR = "error",
  REQUESTED = "requested",
  RESOLVED = "resolved",
  SKIPPED = "skipped",
}

type EmailIdentityBase = {
  contact: EmailContact;
  status: EmailResolutionStatus;
};

export type CandidateEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.CANDIDATE;
  candidates: EmailSearchResult[];
  inProgress: boolean;
};

export type RequestedEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.REQUESTED;
};

export type SkippedEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.SKIPPED;
};

export type ResolvedEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.RESOLVED;
  entity: Entity;
  confirmed: boolean;
};

export type ErrorEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.ERROR;
  errors: string[];
};

export type EmailIdentity =
  | CandidateEmailIdentity
  | ResolvedEmailIdentity
  | ErrorEmailIdentity
  | RequestedEmailIdentity
  | SkippedEmailIdentity;

const emailEventTypes = [
  "CANDIDATE",
  "RESOLVED",
  "IGNORED",
  "CONFIRMED",
  "REJECTED",
  "ERRORED",
  "UNKNOWN",
] as const;

export type EmailEventType = (typeof emailEventTypes)[number];

export function isEmailEventType(u: unknown): u is EmailEventType {
  return typeof u === "string" && (emailEventTypes as readonly string[]).includes(u);
}

export interface EmailEvent extends AnalyticsEvent {
  email: string;
  type: EmailEventType;
  source?: EmailLookupSource;
  entryPoint: EmailEntryPoint;
  searchResultURLs: string[];
  searchResultJSON: string;
  entityId?: string;
  user?: { id: string; name?: string };
}

export function isEmailEvent(u: unknown): u is EmailEvent {
  if (!isAnalyticsEvent(u)) return false;
  const { email, type, source, entryPoint, searchResultURLs, searchResultJSON } =
    u as Partial<EmailEvent>;
  if (!isEmailEventType(type)) return false;
  if (!isEmailEntryPoint(entryPoint)) return false;
  return typeof email === "string";
}
