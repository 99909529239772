import { atom, map } from "nanostores";

import { toast } from "react-toastify";

import API from "@/client/api";
import extensionScraper from "@/client/extensionScraper";
import trackerWeb from "@/client/trackerWeb";
import { buildStatsToShow } from "@/components/companies/CompanyStats";
import { sanitizeLinks } from "@/crawler/sanitizeLinks";
import { DELAYED_REFRESH_WINDOW, HatchetWorkflow } from "@/hatchet/hatchetTypes";
import errorTracker from "@/lib/errorTracker";
import { loggerWithPrefix } from "@/lib/logger";
import { decodeUnicodeEscapes, unwrapError, updateUnsubscribe } from "@/lib/utils";
import {
  CompanyFact,
  EntityFact,
  FactSet,
  FactType,
  FactValueSet,
  PersonFact,
} from "@/models/facts/facts.types";
import {
  BaseRelationshipData,
  PersonCompanyRelationship,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { feedbackStore } from "@/stores/feedbackStore";
import { uiStore } from "@/stores/uiStore";
import { classifyWorkPositions, findAttribute } from "@/stores/utils";
import {
  Attribute,
  AttributeType,
  ConversationListing,
  CrawlResultSansBody,
  CrunchbaseData,
  Entity,
  EntityType,
  EntityWithAttributes,
  FactsPick,
  FeatureFlag,
  GenericProfile,
  ImageDetails,
  LinkWithDescription,
  MutualConnectionsStatus,
  PipelineProgress,
  PositionClassification,
  PrivateAttributeMutualConnection,
  ProfilePageSection,
  ProfileSection,
  ProfileSections,
  QuestionType,
  SocialAccount,
  SocialPosts,
  SocialServices,
  SourceCat,
  SourceIsRight,
  WorkExperience,
} from "@/types";
import { replaceFaviconsWithLoader, resetFavicons } from "@/utils/domUtils";
import { entityIsUser } from "@/utils/entityUtils";
import { extractSocialInfo, isValidProfileUrl, SocialInfo } from "@/utils/socialUtils";
import {
  EntityFilter,
  EntitySearchQuery,
  EntitySource,
  EntitySourceUpdate,
  Relationship,
} from "@prisma/client";
import posthog from "posthog-js";

const logger = loggerWithPrefix("[entityStore]");

export enum TabId {
  Overview = "overview",
  Analysis = "analysis",
  Sources = "sources",
  Search = "search",
}

export const entityTabs = [
  { id: TabId.Overview, name: "Overview" },
  { id: TabId.Analysis, name: "For You" },
  { id: TabId.Sources, name: "Sources" },
  { id: TabId.Search, name: "Search" },
];

type ProfileSectionMap = Partial<
  Record<ProfilePageSection, number | boolean | undefined | "loading">
>;

type MutualConnectionsData = {
  data: PrivateAttributeMutualConnection;
  entity: EntityWithAttributes | null;
  resolved: boolean;
};

class EntityStore {
  entity = atom<Entity>({} as Entity);

  attributes = atom<Attribute[]>([]);

  facts = map<FactsPick>({});

  sources = atom<EntitySource[]>([]);

  sourceUpdates = atom<EntitySourceUpdate[]>([]);

  crawlResults = map<Record<string, CrawlResultSansBody>>({});

  sections = atom<ProfileSection[]>([]);

  images = atom<ImageDetails[]>([]);

  debug = atom<boolean>(true);

  socials = atom<SocialAccount[]>([]);

  mutualConnections = map<Record<string, MutualConnectionsData>>({});

  mutualConnectionsStatus = atom<MutualConnectionsStatus>(MutualConnectionsStatus.Pending);

  latestProgress = atom<number>(0);

  starredSourcesUpdated = atom<boolean>(false);

  sourceCount = atom<number>(0);

  conversations = atom<ConversationListing[]>([]);

  selectedConversation = atom<ConversationListing | null>(null);

  uiTab = atom<TabId>(TabId.Overview);

  questionType = atom<QuestionType | undefined>(undefined);

  somethingWrongBanner = atom<boolean>(false);

  searchResults = atom<LinkWithDescription[] | "loading" | undefined>(undefined);

  searchState = atom<{ query: string; page: number } | undefined>(undefined);

  progress = atom<PipelineProgress | undefined>(undefined);

  relationships = atom<RelationshipWithEntity[]>([]);

  linkedEntities = map<Record<string, Entity>>({});

  validated = atom<"full" | "partial" | "bad" | "none" | undefined>(undefined);

  hideValidationPrompt = atom<boolean>(false);

  profileSections = map<ProfileSectionMap>({});

  currentSection = atom<ProfilePageSection | undefined>(undefined);

  mediaArticles = atom<CrawlResultSansBody[]>([]);

  workSections = map<Partial<Record<ProfilePageSection, WorkExperience[]>>>({});

  relationshipSections = map<Partial<Record<ProfilePageSection, RelationshipWithEntity[]>>>({});

  numberedSources = atom<string[]>([]);

  fundingData = atom<CrunchbaseData | undefined>(undefined);

  searchFilter = atom<EntityFilter | undefined>(undefined);

  searchQuery = atom<EntitySearchQuery | undefined>(undefined);

  // Key is entity id, value is whether the entity is currently being updated, if entity id is in the queue regardless of value we can safely assume user has triggered an update previously
  updateEntityQueue = map<Record<string, { resolved: boolean }>>({});

  // since we map url -> entity we don't need to reset this between entities
  linkedCompanyMap = map<Record<string, EntityWithAttributes>>({});

  // used to track when a summary is being generated for a source
  summariesInProgress = atom<Record<string, boolean>>({});

  showFactEditModal = atom<
    | {
        type: FactType;
        currentValue?: string;
      }
    | undefined
  >();

  isYou = atom<boolean>(false);

  overviewRefreshTriggered = atom<string | undefined>(undefined);

  snapshot = atom<GenericProfile>({} as GenericProfile);

  // dev mode only
  aliases = atom<string[]>([]);

  // --- actions

  load = async (
    entity: Entity,
    snapshot: GenericProfile,
    props: { attributes: Attribute[]; facts?: FactSet; sourcesOnly?: boolean; aliases?: string[] },
  ) => {
    const { attributes, facts, sourcesOnly, aliases } = props;
    logger.info("loaded snapshot", snapshot);

    replaceFaviconsWithLoader();
    // if we're loading a new entity, we need to reset the store
    if (this.entity.get()?.id != entity.id) {
      this.sections.set([]);
      this.sources.set([]);
      this.crawlResults.set({});
      this.starredSourcesUpdated.set(false);
      this.conversations.set([]);
      this.selectedConversation.set(null);
      this.uiTab.set(TabId.Overview);
      this.questionType.set(undefined);
      this.somethingWrongBanner.set(false);
      this.searchResults.set(undefined);
      this.searchState.set(undefined);
      this.sourceCount.set(0);
      this.relationships.set([]);
      this.mediaArticles.set([]);
      this.validated.set(undefined);
      this.numberedSources.set([]);
      this.progress.set(undefined);
      this.facts.set({});
      this.searchFilter.set(undefined);
      this.searchQuery.set(undefined);
      this.workSections.set({});
      this.relationshipSections.set({});
      this.linkedEntities.set({});
      this.mutualConnections.set({});
      this.mutualConnectionsStatus.set(MutualConnectionsStatus.Pending);

      const user = uiStore.user.get();
      this.isYou.set(user ? entityIsUser(entity, user) : false);
      this.snapshot.set(snapshot);
    }

    this.entity.set(entity);
    this.attributes.set(attributes);
    this.snapshot.set(snapshot);
    this.aliases.set(aliases || []);
    if (snapshot?.facts) this.facts.set(snapshot?.facts);

    void this.subscribe(entity.id);
    logger.info("loading entity", entity);

    if (entity.description?.includes("\\u")) {
      entity.description = decodeUnicodeEscapes(entity.description);
    }

    try {
      if (sourcesOnly) {
        await Promise.all([this.loadSources(entity), this.loadSearchFilter(entity)]);
      } else {
        this.initProfileSections(entity, attributes);
        await Promise.all([
          this.loadSources(entity),
          this.loadSections(entity),
          this.loadRelationships(entity),
          this.loadMutualConnections(entity),
          feedbackStore.load(entity),
        ]);
        if (uiStore.showDevTools()) {
          void this.loadSearchFilter(entity);
          void this.loadSearchQuery(entity);
        }
        this.loadFundingData(entity);
      }
    } catch (e) {
      toast.warn("Error loading page data");
      logger.error("error loading entity", e);
    } finally {
      if (this.progress.get() === undefined) {
        // In case some update is in progress, we will reset the favicon
        // in subscribe method.
        resetFavicons();
      }
    }
  };

  initProfileSections = (entity: Entity, attributes: Attribute[]) => {
    const linkedinProfile = findAttribute(attributes, AttributeType.LinkedinProfile)?.value;
    const workSections: Partial<Record<ProfilePageSection, WorkExperience[]>> = {
      // default state for works sections for section order purposes
      [ProfilePageSection.WorkHistory]: [],
      [ProfilePageSection.Investments]: [],
      [ProfilePageSection.OtherExperience]: [],
    };

    linkedinProfile?.experience?.forEach((workExperience) => {
      let section = ProfilePageSection.WorkHistory;

      const positionClassifications = classifyWorkPositions(workExperience);
      const positionTypesMatch = positionClassifications.every(
        (p) => p === positionClassifications[0],
      );
      const overallClassification =
        (positionTypesMatch && positionClassifications[0]) || PositionClassification.Other;

      const classificationToSection: Record<PositionClassification, ProfilePageSection> = {
        [PositionClassification.Board]: ProfilePageSection.Investments,
        [PositionClassification.Investor]: ProfilePageSection.Investments,
        [PositionClassification.Volunteer]: ProfilePageSection.OtherExperience,
        [PositionClassification.Advisor]: ProfilePageSection.OtherExperience,
        [PositionClassification.Other]: ProfilePageSection.WorkHistory,
      };

      section = classificationToSection[overallClassification] || section;
      const list = (workSections[section] = workSections[section] || []);
      list.push(workExperience);
    });

    const facts = this.facts.get();
    const stats =
      entity.type == EntityType.Company ? buildStatsToShow(facts).length || undefined : undefined;

    const historicalHeadcount = facts[CompanyFact.HistoricalHeadcount]?.value;
    const hasHeadcount = historicalHeadcount && historicalHeadcount.length > 0;
    const hasEmployees =
      this.relationships.get().filter((r) => r.type === PersonCompanyRelationship.WorkedAt).length >
      0;
    const shouldShowPeople = hasHeadcount || hasEmployees;

    const sections: ProfileSectionMap = {
      [ProfilePageSection.Highlights]: true,
      [ProfilePageSection.About]: !!facts[EntityFact.About]?.value,
      [ProfilePageSection.MutualConnections]: Object.values(this.mutualConnections.get()).length,
      [ProfilePageSection.Stats]: stats,
      [ProfilePageSection.People]: shouldShowPeople,
      [ProfilePageSection.WorkHistory]: workSections[ProfilePageSection.WorkHistory]?.length,
      [ProfilePageSection.Investments]: workSections[ProfilePageSection.Investments]?.length,
      [ProfilePageSection.OtherExperience]:
        workSections[ProfilePageSection.OtherExperience]?.length,
      [ProfilePageSection.Education]: linkedinProfile?.education?.length,
      [ProfilePageSection.Funding]: false,
      [ProfilePageSection.Sources]: true,
    };

    this.profileSections.set(sections);
    this.workSections.set(workSections);
  };

  extractSocialInfos = (relevantSources: EntitySource[], entity: Entity): SocialInfo[] => {
    return relevantSources
      .map((s) => s.url)
      .concat(entity.url)
      .reduce<SocialInfo[]>((acc, socialUrl) => {
        if (isValidProfileUrl(socialUrl)) {
          const socialInfo = extractSocialInfo(socialUrl);
          if (socialInfo) {
            acc.push(socialInfo);
          }
        }
        return acc;
      }, []);
  };

  loadPosts = async (infos: SocialInfo[]) => {
    type SocialInfoWithPosts = SocialInfo & {
      posts?: SocialPosts;
      followers?: number;
      following?: number;
      recentPostCount?: number;
    };
    return Promise.all(
      infos.reduce<Promise<SocialInfoWithPosts>[]>((acc, socialInfo) => {
        const { service, url } = socialInfo;
        if (service === SocialServices.Twitter) {
          acc.push(
            new Promise((resolve) => {
              return API.fetchSocialPosts({ service, url }).then((posts) => {
                resolve({
                  ...socialInfo,
                  posts: posts || undefined,
                  followers: posts?.followers,
                  following: posts?.following,
                  recentPostCount: posts?.recentPostCount,
                });
              });
            }),
          );
        } else {
          acc.push(Promise.resolve(socialInfo));
        }
        return acc;
      }, []),
    );
  };

  extractLinkedInProfileAttributes(url: string): { followers: string; connections: string } | {} {
    const liProfile = findAttribute(this.attributes.get(), AttributeType.LinkedinProfile)?.value
      ?.profile;
    return liProfile ?
        {
          followers: liProfile.followers,
          connections: liProfile.connections,
        }
      : {};
  }

  loadSocials = async (
    relevantSources: EntitySource[],
    entity: Entity,
  ): Promise<SocialAccount[]> => {
    const infos = this.extractSocialInfos(relevantSources, entity);
    const socials = await this.loadPosts(infos)
      .then((infosWithPosts) => {
        return infosWithPosts.map(({ service, url, username, posts }) => {
          const liProfileAttributes = this.extractLinkedInProfileAttributes(url);
          return {
            service,
            url,
            username,
            posts: posts?.posts,
            followers: posts?.followers,
            following: posts?.following,
            recentPostCount: posts?.recentPostCount,
            ...(service === SocialServices.LinkedIn ? liProfileAttributes : {}),
          };
        });
      })
      .then((socials) => {
        return socials.reduce<Record<string, SocialAccount>>((acc, s) => {
          const key = `${s.service}:${s.username}`.toLowerCase();
          if (!acc[key] || acc[key].url === entity.url) {
            acc[key] = s;
          } else {
            logger.debug("Ignoring duplicate social account", key);
            logger.debug("Existing", acc[key]);
            logger.debug("New", s);
          }
          return acc;
        }, {});
      });
    return Object.values(socials);
  };

  loadMutualConnections = async (entity: Entity) => {
    //only load mutual connections for people for now
    if (entity.type !== EntityType.Person) return;

    const user = uiStore.user.get();
    if (!user || entityIsUser(entity, user)) {
      return;
    }

    try {
      const data = await API.getMutualConnections({
        entityId: entity.id,
      });
      this.mutualConnectionsStatus.set(data.status);
      if (data.status === MutualConnectionsStatus.Pending) {
        this.mutualConnections.set({});
        this.profileSections.setKey(ProfilePageSection.MutualConnections, 0);
      }
      if (data.connections.length) {
        data.connections.forEach((connection) => {
          const resolved = this.mutualConnections.get()[connection.linkedinUrl]?.resolved;
          const asEntity = this.mutualConnections.get()[connection.linkedinUrl]?.entity;
          this.mutualConnections.setKey(connection.linkedinUrl, {
            data: connection,
            resolved: resolved || false,
            entity: asEntity,
          });
        });
        this.profileSections.setKey(ProfilePageSection.MutualConnections, data.connections.length);
      }
      if (
        data.status === MutualConnectionsStatus.Pending ||
        data.status === MutualConnectionsStatus.Reloading
      ) {
        void this.subscribeToMutualConnections(entity.id);
      }
    } catch (e) {
      toast.error("Error loading mutual connections");
      logger.error(
        `Error loading mutual connections for user ${uiStore.user.get()?.id} and entity ${entity.id}`,
        e,
      );
    }
  };

  resolveMutualConnections = async (
    start?: number,
    end?: number,
  ): Promise<Record<string, EntityWithAttributes> | null> => {
    start = start || 0;
    end = end || undefined;

    const batch = Object.values(this.mutualConnections.get())
      .slice(start, end)
      .filter((c) => !c.resolved);
    if (!batch.length) return null;

    // debugger;
    const resolved = (await API.resolveAllEntities({
      queries: batch.map((c) => c.data.linkedinUrl),
    })) as Record<string, EntityWithAttributes> | null; // Cast the resolved value
    if (resolved) {
      Object.entries(resolved).forEach(([url, entity]) => {
        this.mutualConnections.setKey(url, {
          ...this.mutualConnections.get()[url],
          entity,
          resolved: true,
        });
      });
    }
    // disabling createUnresolvedMutualConnections for now
    // --------------------------------------------------
    // const unresolved = Object.values(this.mutualConnections.get())
    //   .slice(start, end)
    //   .filter((c) => !c.entity);
    // void this.createUnresolvedMutualConnections(unresolved);
    return resolved;
  };

  createUnresolvedMutualConnections = async (
    unresolved: MutualConnectionsData[],
  ): Promise<void> => {
    if (unresolved.length) {
      const urls = unresolved.map((c) => c.data.linkedinUrl);
      const entities = await Promise.all(
        urls.map(async (url) => {
          return await API.createEntity({ url });
        }),
      );
      entities.forEach((entity, index) => {
        if (entity) {
          this.mutualConnections.setKey(urls[index], {
            ...this.mutualConnections.get()[urls[index]],
            entity: entity as EntityWithAttributes,
            resolved: true,
          });
        }
      });
    }
  };

  loadSources = async (entity: Entity) => {
    const data = await API.getSources({ entityId: entity.id });
    data.sources.sort((a, b) =>
      b.starred && !a.starred ? 1
      : a.starred && !b.starred ? -1
      : (b.relevance ?? 5) - (a.relevance ?? 5),
    );
    this.sources.set(data.sources);
    const relevantSources = data.sources.filter(
      (s) => (s.starred || (s.relevance ?? 5) > 3) && s.isRight != SourceIsRight.No,
    );
    const sourceCount = relevantSources.length;
    this.sourceCount.set(sourceCount);
    this.images.set(data.images);
    data.scraped.forEach((result) => {
      this.crawlResults.setKey(result.url, result);
    });
    this.sourceUpdates.set(data.sourceUpdates.filter((update) => !update.deletedAt));

    const socials = await this.loadSocials(relevantSources, entity);

    this.socials.set(socials);
    this.profileSections.setKey(ProfilePageSection.SocialMedia, socials.length);
    this.profileSections.setKey(ProfilePageSection.Sources, sourceCount || true);

    const crawlResults = this.crawlResults.get();
    const media = relevantSources
      .map((s) => crawlResults[s.url])
      .filter(Boolean)
      .filter((c) =>
        c.structuredData?.find((s) => (s["@type"] as string | undefined)?.includes("Article")),
      );
    this.mediaArticles.set(media);
    this.profileSections.setKey(ProfilePageSection.MediaCoverage, media.length);
  };

  loadSections = async (entity: Entity) => {
    const data = await API.getSections({ entityId: entity.id });
    this.sections.set(data);

    const webOverview = data.find((s) => s.section == ProfileSections.WebLinks);
    if (webOverview) {
      const bullets = webOverview.data.bullets || [];
      const numberedSources: string[] = [];
      const sourceSet = new Set<string>();
      bullets.forEach((b) => {
        b.sources?.forEach((s) => {
          if (!sourceSet.has(s)) {
            numberedSources.push(s);
            sourceSet.add(s);
          }
        });
      });
      this.numberedSources.set(numberedSources);
    }
  };

  loadFundingData = (entity: Entity) => {
    if (entity.type === EntityType.Person) return;
    const crawlResults = this.crawlResults.get();

    const crunchbase = this.sources
      .get()
      .find(
        (r) =>
          r.relevance &&
          r.relevance > 3 &&
          r.url.includes("crunchbase.com/organization") &&
          crawlResults[r.url]?.structuredData?.length,
      );

    const crunchbaseData =
      crunchbase &&
      (crawlResults[crunchbase.url].structuredData?.find((s) => s.mainEntity)
        ?.mainEntity as CrunchbaseData);

    this.fundingData.set(crunchbaseData);
    this.profileSections.setKey(ProfilePageSection.Funding, !!crunchbaseData);
  };

  loadRelationships = async (entity: Entity) => {
    const relationships = await API.relationships.list({ entityId: entity.id, type: entity.type });
    const relationshipsTo = await API.relationshipsTo({ entityId: entity.id });
    const allRelationships = [...relationships, ...relationshipsTo];
    const filteredRelationships = allRelationships.filter((r) => !!r.toId || !!r.toName);
    if (allRelationships.length !== filteredRelationships.length) {
      errorTracker.sendError(new Error("Relationships with missing toId or toName"), {
        entityId: entity.id || "",
        entityType: entity.type || "",
        slug: entity.slug || "",
      });
    }
    this.relationships.set(filteredRelationships);

    const linkedEntities = filteredRelationships
      .filter((r) => r.toId && r.fromId)
      .map((r) => {
        const isToSelf = r.toId == entity.id;
        const key = isToSelf ? r.fromId : r.toId;
        const value = isToSelf ? r.from : r.to;

        return [key, value] as [string, EntityWithAttributes];
      });
    this.linkedEntities.set(Object.fromEntries(linkedEntities));

    if (entity.type == EntityType.Person) {
      // coming soon
      this.buildWorkAndEducationSections(relationships.filter((r) => !!r.toName || !!r.toId));
    }
  };

  updateRelationship = async (relationship: Partial<RelationshipWithEntity> & { id: string }) => {
    const currentRelationships = this.relationships.get() || [];
    const updatedRelationships = currentRelationships.map((r) =>
      r.id === relationship.id ? { ...r, ...relationship } : r,
    );
    this.relationships.set(updatedRelationships);
    this.buildWorkAndEducationSections(updatedRelationships);
    try {
      await API.relationships.update(relationship.id, relationship);
    } catch (e) {
      // Reverting optimistic update.
      this.relationships.set(currentRelationships);
      this.buildWorkAndEducationSections(currentRelationships);
      throw e;
    }
  };

  createRelationship = async (relationship: Partial<RelationshipWithEntity>) => {
    // No optimistic update here in order to make sure that all the data in the
    // store represents some entity in the database. Otherwise editing will
    // fail (since there is no id).
    const currentRelationships = this.relationships.get() || [];
    const addedRelationship = await API.relationships.create(relationship);
    const updatedRelationships = [...currentRelationships, addedRelationship];
    this.relationships.set(updatedRelationships);
    this.buildWorkAndEducationSections(updatedRelationships);
  };

  deleteRelationship = async (relationship: Partial<RelationshipWithEntity> & { id: string }) => {
    const currentRelationships = this.relationships.get() || [];
    const updatedRelationships = currentRelationships.filter((r) => r.id !== relationship.id);
    this.relationships.set(updatedRelationships);
    this.buildWorkAndEducationSections(updatedRelationships);
    try {
      await API.relationships.delete(relationship.id);
    } catch (e) {
      // Reverting optimistic update.
      this.relationships.set(currentRelationships);
      this.buildWorkAndEducationSections(currentRelationships);
      throw e;
    }
  };

  batchDeleteRelationships = async ({
    toId,
    toName,
    entityId,
  }: {
    toId?: string;
    toName?: string;
    entityId: string;
  }) => {
    if (toId && toName) {
      throw new Error("toId and toName are mutually exclusive");
    }
    const currentRelationships = this.relationships.get() || [];
    const updatedRelationships = currentRelationships.filter(
      (r) => r.toId !== toId && r.toName !== toName,
    );
    this.relationships.set(updatedRelationships);
    this.buildWorkAndEducationSections(updatedRelationships);
    try {
      await API.batchDeleteRelationships({
        toId,
        toName,
        entityId,
      });
    } catch (e) {
      // Reverting optimistic update.
      this.relationships.set(currentRelationships);
      this.buildWorkAndEducationSections(currentRelationships);
      throw e;
    }
  };

  batchUpdateRelationships = async (
    {
      toId,
      toName,
      entityId,
    }: {
      toId?: string;
      toName?: string;
      entityId: string;
    },
    updates: Partial<Relationship>,
  ) => {
    if (toId && toName) {
      throw new Error("toId and toName are mutually exclusive");
    }
    const currentRelationships = this.relationships.get() || [];
    const updatedRelationships = currentRelationships.map((r) => {
      if (r.toId === toId || r.toName === toName) {
        return { ...r, ...updates };
      }
      return r;
    });
    this.relationships.set(updatedRelationships);
    this.buildWorkAndEducationSections(updatedRelationships);
    try {
      await API.batchUpdateRelationships(updates, {
        toId,
        toName,
        entityId,
      });
      if (updates.toId || updates.toName) {
        await this.loadRelationships(this.entity.get());
      }
    } catch (e) {
      // Reverting optimistic update.
      this.relationships.set(currentRelationships);
      this.buildWorkAndEducationSections(currentRelationships);
      throw e;
    }
  };

  buildWorkAndEducationSections = (relationships: RelationshipWithEntity[]) => {
    const relationshipSections: Partial<Record<ProfilePageSection, RelationshipWithEntity[]>> = {};

    relationships.forEach((relationship) => {
      const section =
        (
          relationship.type == PersonCompanyRelationship.WorkedAt ||
          relationship.type == PersonCompanyRelationship.Founded
        ) ?
          ProfilePageSection.WorkHistory
        : relationship.type == PersonCompanyRelationship.EducatedAt ? ProfilePageSection.Education
        : relationship.type == PersonCompanyRelationship.InvestedIn ? ProfilePageSection.Investments
        : relationship.type == PersonCompanyRelationship.VolunteeredAt ?
          ProfilePageSection.Volunteering
        : relationship.type == PersonCompanyRelationship.OtherExperience ?
          ProfilePageSection.OtherExperience
        : undefined;

      if (section) {
        relationshipSections[section] = [...(relationshipSections[section] || []), relationship];
      }
    });

    // sort all arrays by order, if everything has an order field
    // if not, sort by date, using order as a tie-breaker
    Object.values(relationshipSections).forEach((section) => {
      const allItemsHaveOrder = section.every(
        (r) => (r.data as BaseRelationshipData)?.order !== undefined,
      );

      section.sort((a, b) => {
        const aOrder = (a.data as BaseRelationshipData)?.order || 0;
        const bOrder = (b.data as BaseRelationshipData)?.order || 0;

        if (allItemsHaveOrder) {
          return bOrder - aOrder;
        }

        if (!a.endedDate && !b.endedDate) {
          if (b.startedDate == a.startedDate) {
            return bOrder - aOrder;
          }
          return b.startedDate?.localeCompare(a.startedDate || "") || 0;
        } else if (!a.endedDate || a.endedDate == "Present") {
          return -1;
        } else if (!b.endedDate || b.endedDate == "Present") {
          return 1;
        } else {
          if (b.endedDate == a.endedDate) {
            return bOrder - aOrder;
          }
          return b.endedDate.localeCompare(a.endedDate);
        }
      });
    });
    this.relationshipSections.set({ ...relationshipSections });
  };

  loadSearchFilter = async (entity: Entity) => {
    if (!uiStore.showDevTools()) return;
    const filter = await API.getFilter({ entityId: entity.id });
    this.searchFilter.set(filter);
  };

  loadSearchQuery = async (entity: Entity) => {
    if (!uiStore.showDevTools()) return;
    const query = await API.getQuery({ entityId: entity.id });
    this.searchQuery.set(query);
  };

  // This triggers a whole update workflow.
  // This should NOT be used to modify the entity itself.
  triggerEntityRefresh = async () => {
    const entityId = this.entity.get().id;
    void this.subscribe(entityId);
    this.updateEntityQueue.set({
      ...this.updateEntityQueue.get(),
      [entityId]: { resolved: false },
    });
    trackerWeb.capture("update-entity", { entityId: entityId });
    try {
      await API.triggerEntityRefresh({ entityId: entityId });
    } catch (e) {
      errorTracker.sendError(e, { source: "update-entity" });
    }
  };

  updateEntityFields = async (id: string, updates: Partial<Entity>) => {
    const currentEntity = this.entity.get();
    if (currentEntity.id === id) {
      // optimistic update
      this.entity.set({ ...currentEntity, ...updates });
    }
    try {
      const newEntity = await API.entities.update(id, updates);
      this.entity.set(newEntity);
    } catch (e) {
      if (currentEntity.id === id) {
        // revert optimistic update
        this.entity.set(currentEntity);
      }
      logger.error(unwrapError(e));
      toast.error("Error updating entity fields: " + unwrapError(e));
    }
  };

  addSource = () => {
    uiStore.showInputModal.set({
      type: "add",
      title: "Add Source",
      subtitle: "Enter the URL of a source to add",
      fields: [
        {
          placeholder: "https://example.com",
        },
      ],
      onSubmit: async (values: string[]) => {
        const url = values[0];
        await this.addSourceUrl(url, "manual");
      },
    });
  };

  addSourceUrl = async (url: string, sourceOfSource: string) => {
    const _validated = new URL(url); // parse url
    trackerWeb.capture("entity-add-source", {
      entityId: this.entity.get().id,
      url,
      sourceOfSource,
    });
    this.starredSourcesUpdated.set(true);
    try {
      const source = await API.sources.create({
        entityId: this.entity.get().id,
        url,
        source: sourceOfSource,
      });
      await this.loadSingleSource(source.id);
      toast.success("Source added", { autoClose: 2000 });
      await this.triggerOverviewRefresh();
      void this.summarizeSource(source);
    } catch (e) {
      errorTracker.sendError(e, { source: "add-source-url" });
      toast.error("Error adding source: " + unwrapError(e));
    }
  };

  triggerOverviewRefresh = async () => {
    const lastRefresh = this.overviewRefreshTriggered.get();
    const refreshWindow = DELAYED_REFRESH_WINDOW;
    if (
      lastRefresh == undefined ||
      new Date().getTime() - new Date(lastRefresh).getTime() > refreshWindow
    ) {
      toast.info("Scheduling profile refresh...");
      try {
        await API.triggerHatchetEvent({
          event: HatchetWorkflow.RegenerateOverview,
          input: {
            userId: uiStore.user.get()?.id,
            entityId: this.entity.get().id,
            delayUntil: new Date(new Date().getTime() + refreshWindow).toISOString(),
            skipCache: true,
          },
        });
      } catch (error) {
        logger.error("Error triggering regenerate-overview", error);
        toast.error("Failed to regenerate overview");
      }
    }
    this.overviewRefreshTriggered.set(new Date().toISOString());
  };

  summarizeSource = async (source: EntitySource, resummarize: boolean = false) => {
    if (source.summary && !resummarize) return;
    this.summariesInProgress.set({ ...this.summariesInProgress.get(), [source.url]: true });
    try {
      const response = await API.summarizeSource({
        entityId: this.entity.get().id,
        sourceId: source.id,
      });
      const sanitized = sanitizeLinks([source.url])[0];
      const summarized = response.summarized[sanitized] || response.summarized[source.url];
      if (summarized) {
        this.sources.set(
          this.sources
            .get()
            .map((s) => (s.url == source.url ? { ...s, summary: summarized.join("\n") } : s)),
        );
      } else {
        errorTracker.sendError(new Error("No summary generated for source"), {
          source: "summarize-source",
          url: source.url,
        });
        toast.error("No summary generated for source: " + source.url);
      }
    } catch (e) {
      errorTracker.sendError(e, { source: "summarize-source" });
    } finally {
      this.summariesInProgress.set({ ...this.summariesInProgress.get(), [source.url]: false });
    }
  };

  upsertNote = (source: EntitySource, existingNote?: string) => {
    const isAddingNote = !existingNote;
    const modalType = isAddingNote ? "add" : "edit";
    const modalTitle = isAddingNote ? "Add a note to the AI" : "Edit Note";
    const modalSubtitle =
      isAddingNote ?
        "Help our AI understand this source better - how is it related? Is anything incorrect?"
      : "Edit the note for this source";

    uiStore.showInputModal.set({
      type: modalType,
      title: modalTitle,
      subtitle: modalSubtitle,
      fields: [
        {
          placeholder: "Enter your note",
          currentValue: existingNote,
        },
      ],
      onSubmit: async (values: string[]) => {
        const notes = values[0];
        const newNote = await API.sourceUpdates.create(this.entity.get(), {
          url: source.url,
          notes,
        });
        this.sourceUpdates.set([
          ...this.sourceUpdates.get().filter((u) => u.url != source.url),
          newNote,
        ]);
        void this.summarizeSource(source, true);
        await this.triggerOverviewRefresh();
      },
    });
  };

  deleteNote = async (source: EntitySource) => {
    const update = this.sourceUpdates.get().find((u) => u.url === source.url);
    if (update) {
      await API.sourceUpdates.update(this.entity.get().id, update.id, { deletedAt: new Date() });
      this.sourceUpdates.set(this.sourceUpdates.get().filter((u) => u.url != source.url));
      await this.triggerOverviewRefresh();
    }
  };

  starSource = async (url: string, starred: boolean) => {
    const sources = this.sources.get();
    const source = sources.find((s) => s.url == url);
    if (!source) return;

    this.sources.set(sources.map((s) => (s.url == url ? { ...s, starred } : s)));
    this.starredSourcesUpdated.set(true);
    trackerWeb.capture(starred ? "entity-star-source" : "entity-unstar-source", {
      entityId: this.entity.get().id,
    });
    try {
      await API.sources.update(source.id, { starred });
    } catch (e) {
      errorTracker.sendError(e, { source: "star-source" });
      logger.error(unwrapError(e));
      toast.error("Error updating source");
    }
  };

  markRelevant = async (url: string, relevant: boolean) => {
    const sources = this.sources.get();
    const source = sources.find((s) => s.url == url);
    if (!source) return;

    const wasStarred = source.starred;
    const relevance = relevant ? 5 : 0;
    this.sources.set(
      sources.map((s) =>
        s.url == url ? { ...s, relevance, starred: !relevant ? false : s.starred } : s,
      ),
    );
    if (wasStarred) this.starredSourcesUpdated.set(true);
    trackerWeb.capture(relevant ? "entity-mark-relevant" : "entity-mark-unhelpful", {
      starred: source.starred,
      entityId: this.entity.get().id,
    });
    try {
      await API.sources.update(source.id, {
        relevance,
        starred: !relevant ? false : source.starred,
      });
    } catch (e) {
      errorTracker.sendError(e, { source: "mark-relevant" });
      logger.error(unwrapError(e));
      toast.error("Error updating source");
    }
  };

  updateCategory = async (source: EntitySource, category: SourceCat) => {
    const updates: Partial<EntitySource> & { category: SourceCat } = {
      category,
      hidden: false,
    };
    if (source.error) updates.error = null;
    switch (category) {
      case SourceCat.Wrong:
        updates.isRight = SourceIsRight.No;
        break;
      case SourceCat.Starred:
        updates.isRight = SourceIsRight.Yes;
        updates.starred = true;
        break;
      case SourceCat.Unsure:
        updates.isRight = SourceIsRight.Unsure;
        updates.starred = false;
        break;
      case SourceCat.Relevant:
      case SourceCat.Unhelpful:
        updates.starred = false;
        updates.isRight = SourceIsRight.Yes;
        updates.relevance = category == SourceCat.Relevant ? 5 : 0;
        break;
      case SourceCat.Hidden:
        updates.starred = false;
        updates.hidden = true;
        break;
      case SourceCat.Duplicate:
        updates.starred = true;
        updates.isRight = SourceIsRight.Duplicate;
        break;
      default:
        logger.warn("did not modify source", source, category);
        return;
    }

    this.starredSourcesUpdated.set(true);
    const updatedSource = { ...source, ...updates };
    logger.info("source updated", category, updatedSource);
    trackerWeb.capture("entity-source-categorize", {
      source: source.url,
      category: category,
      entityId: this.entity.get().id,
    });
    this.sources.set(this.sources.get().map((s) => (s.url == source.url ? updatedSource : s)));
    try {
      await API.sources.update(source.id, updates);
    } catch (e) {
      errorTracker.sendError(e, { source: "update-category" });
      logger.error(unwrapError(e));
      toast.error("Error updating source");
    }
    if (updatedSource.starred || (updatedSource.relevance ?? 0) > 3) {
      await this.summarizeSource(updatedSource);
    }
    await this.triggerOverviewRefresh();
  };

  regenerateOverview = () => {
    uiStore.showConfirmModal.set({
      type: "warning",
      title: "Regenerate Overview",
      subtitle: "Regenerate overview based on updated starred articles?",
      onClick: async () => {
        trackerWeb.capture("entity-regenerate-overview", { entityId: this.entity.get().id });
        const promise = API.sourcesRegenerate({ entityId: this.entity.get().id });
        await toast.promise(promise, {
          pending: "Regenerating overview...",
          success: "Finished!",
          error: "Error generating overview",
        });
      },
    });
  };

  deleteHighlights = () => {
    uiStore.showConfirmModal.set({
      type: "danger",
      title: "Delete Highlights?",
      subtitle: "You will need to rebuild this profile.",
      onClick: async () => {
        this.sections.set([]);
        await API.deleteSections({ entityId: this.entity.get().id });
      },
    });
  };

  clearAllSources = () => {
    uiStore.showConfirmModal.set({
      type: "danger",
      title: "Clear all sources",
      subtitle: "You will need to rebuild this profile.",
      onClick: async () => {
        await API.sourcesClearAll({ entityId: this.entity.get().id });
        void this.loadSources(this.entity.get());
      },
    });
  };

  loadSingleSource = async (id: string) => {
    const source = await API.getSources({ entityId: this.entity.get().id, sourceId: id });
    const sources = [...this.sources.get(), ...source.sources];
    sources.sort((a, b) =>
      b.starred && !a.starred ? 1
      : a.starred && !b.starred ? -1
      : (b.relevance ?? 5) - (a.relevance ?? 5),
    );
    this.sources.set(sources);
    source.scraped.forEach((c) => {
      this.crawlResults.setKey(c.url, c);
    });
  };

  deleteEntity = () => {
    if (!uiStore.showDevTools()) return;
    uiStore.showConfirmModal.set({
      type: "danger",
      title: "Delete Entity",
      subtitle: "Are you sure? You will need to rebuild this profile.",
      onClick: async () => {
        try {
          const result = await API.deleteEntity({ entityId: this.entity.get().id });
          if (result.redirect) {
            uiStore.routeTo(result.redirect);
            // this was displaying before the redirect and being lost
            setTimeout(() => {
              toast.success("Entity deleted");
            }, 1000);
          }
        } catch (e) {
          errorTracker.sendError(e, { source: "delete-entity" });
          toast.error("Error deleting entity: " + unwrapError(e));
        }
      },
    });
  };

  search = async (query: string, page?: number) => {
    if (!uiStore.user.get()) return;
    this.searchState.set({ query, page: page || 0 });
    this.searchResults.set("loading");
    try {
      const results = await extensionScraper.searchWeb({ q: query, page: page });
      this.searchResults.set(results);
    } catch (e) {
      errorTracker.sendError(e, { source: "entity-search" });
      toast.error("Error searching: " + unwrapError(e));
      this.searchResults.set(undefined);
    }
  };

  triggerPipeline = async () => {
    const entity = this.entity.get();
    const event =
      entity.type == EntityType.Person ?
        HatchetWorkflow.PersonLoader
      : HatchetWorkflow.CompanyLoader;
    const user = uiStore.user.get();
    if (!user) return;
    await API.triggerHatchetEvent({
      event,
      input: {
        entityId: entity.id,
        userId: user.id,
        reason: "ui button",
      },
    });
  };

  loadLinkedCompany = async (url: string) => {
    await API.resolveEntity({ query: url }).then((entitySnap) => {
      if (entitySnap) this.linkedCompanyMap.setKey(url, entitySnap.entity);
    });
  };

  isRefreshing = false;
  refresh = () => {
    const router = uiStore.router;
    if (this.isRefreshing || !router) return;
    this.isRefreshing = true;
    // generate a new path, which invalidates cache
    const url = new URL(window.location.href);
    url.searchParams.set("refresh", Date.now().toString());
    uiStore.routeTo(url.pathname + url.search);
    setTimeout(() => {
      this.isRefreshing = false;
    }, 1000);
  };

  updateFact = async <T extends FactType>(type: T, value: FactValueSet[T]) => {
    logger.info("updateFact", type, value);
    trackerWeb.capture("entity-update-fact", {
      isYou: this.isYou.get(),
      type,
      value: String(value),
    });
    const entity = this.entity.get();
    try {
      const fact = await API.facts.create(entity, { entityId: entity.id, type, value });
      const snapshot = await API.getSnapshot({ entityId: entity.id });
      this.facts.set({ ...this.facts.get(), [type]: fact });
      this.snapshot.set(snapshot);

      if (type == PersonFact.Pronouns) void this.onPronounsUpdated(value as string);
    } catch (e) {
      logger.error("Error updating fact", e);
      toast.error(unwrapError(e));
    }
  };

  private onPronounsUpdated = async (pronouns: string) => {
    const reloading = API.pronounsUpdated({ entityId: this.entity.get().id, pronouns });
    await toast.promise(reloading, {
      pending: "Re-writing profile...",
      success: "Finished!",
      error: "Error re-writing profile",
    });
    this.refresh();
  };

  updateEntityImage = async (imgSrc: string) => {
    const newEntity = await API.entities.update(this.entity.get().id, {
      imageUrl: imgSrc,
    });
    this.entity.set(newEntity);
  };

  mergeEntities = () => {
    uiStore.showInputModal.set({
      type: "warning",
      title: "Merge Entities",
      subtitle: "Enter the URL of the new entity. THIS CURRENT ENTITY will be NO MORE.",
      fields: [
        {
          placeholder: "https://distill.fyi/c/NewCo",
        },
      ],
      onSubmit: async (values: string[]) => {
        const url = values[0];
        const result = await API.mergeEntities({
          fromEntityId: this.entity.get().id,
          toEntityUrl: url,
        });
        if (result.redirect) {
          uiStore.routeTo(result.redirect);
        }
      },
    });
  };

  // --- live subscription

  unsubscribe: (() => void) | null = null;

  subscribe = async (entityId: string) => {
    if (!uiStore.user.get()) return;
    const realtime = await uiStore.getConnectedRealtime();
    const channel = realtime.channels.get("entity:" + entityId);

    if (channel) {
      updateUnsubscribe(this, () => channel.unsubscribe());

      channel.subscribe("reload", (msg) => {
        // reloads entity data from the server (without refreshing the whole page)
        this.refresh();
      });

      channel.subscribe("refresh-beta", (msg) => {
        posthog.onFeatureFlags(() => {
          if (posthog.isFeatureEnabled(FeatureFlag.BetaUI)) {
            logger.info("betaUI enabled, refreshing");
            this.refresh();
          }
        });
      });

      channel.subscribe("progress", (msg) => {
        const progress = msg.data as PipelineProgress;
        logger.info("progress->", msg.data);
        const currentProgress = this.progress.get();
        if (!currentProgress) {
          replaceFaviconsWithLoader();
        }
        const newProgress: PipelineProgress = {
          ...progress,
          step: Math.max(progress.step || 0, (currentProgress?.step || 0) + 1),
        };
        this.progress.set(newProgress);
      });

      channel.subscribe("finished", (msg) => {
        logger.info("entity finished", msg);
        const visible = document.visibilityState;
        trackerWeb.capture("entity-finished", { entityId, visible });
        this.updateEntityQueue.set({
          ...this.updateEntityQueue.get(),
          [entityId]: { resolved: true },
        });
        this.progress.set(undefined);
        resetFavicons();
        this.refresh();
      });

      channel.subscribe("newSource", (msg) => {
        const { id } = msg.data as { id: string };
        void this.loadSingleSource(id);
      });

      channel.subscribe("newHighlights", (msg) => {
        logger.info("new highlights", msg);
        void this.loadSections(this.entity.get());
      });
    }
  };

  mutualsUnsubscribe: { unsubscribe: (() => void) | null } = { unsubscribe: null };

  subscribeToMutualConnections = async (entityId: string) => {
    if (!uiStore.user.get()) return;
    const userId = uiStore.user.get()?.id || "unknown";
    const realtime = await uiStore.getConnectedRealtime();
    const channel = realtime.channels.get("mutuals:entity:" + entityId + ":user:" + userId);

    if (channel) {
      updateUnsubscribe(this.mutualsUnsubscribe, () => channel.unsubscribe());
    }

    channel.subscribe("mutual-connections-completed", (msg) => {
      logger.info("mutual connections completed, refreshing");
      void this.loadMutualConnections(this.entity.get());
      updateUnsubscribe(this.mutualsUnsubscribe, () => channel.unsubscribe());
    });
  };
}

declare global {
  interface Window {
    entityStore: EntityStore;
  }
}

export const entityStore = new EntityStore();
