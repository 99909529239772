import "@/lib/error";
import { isAxiosError } from "axios";
import { UserVisibleError } from "@/lib/error";

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

function printAxiosData(data: unknown): string | undefined {
  if (typeof data === "string") {
    return data.startsWith("<!DOCTYPE html>") ? undefined : data;
  }
  if (typeof data === "object" && data !== null) {
    const { error: err, message } = data as {
      error?: string | { message: string };
      message?: string;
    };
    if (typeof err === "string") return err;
    if (typeof message === "string") return message;
    if (typeof err === "object" && err !== null) {
      return typeof err.message === "string" ? err.message : JSON.stringify(err);
    }
    return Object.entries(data)
      .filter(([_, value]) => typeof value === "string" || typeof value === "number")
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");
  }
  return undefined;
}

export function unwrapError(error: unknown): string {
  if (!error) return "error was missing";

  if (error instanceof UserVisibleError) {
    return error.message;
  }

  try {
    if (isAxiosError(error)) {
      if (error.code === "ECONNABORTED") return "Timeout";
      if (error.response) {
        const axiosData = printAxiosData(error.response.data);
        return (
          axiosData ?? `Error (Status: ${error.response.status}, ${error.response.statusText})`
        );
      }
      return error.message;
    }
  } catch (e) {
    console.warn("Error unwrapping error", error, e);
    return `Error: ${String(error)}.`;
  }

  if (typeof error === "object") {
    // this wouldn't normally work for Error instances without the toJSON override in @/lib/error
    try {
      return JSON.stringify(error);
    } catch (e) {
      console.warn("Error unwrapping error", error, e);
      return `Error (unable to JSON stringify): ${String(error)}`;
    }
  }

  return String(error);
}

export const favicon = (webpage: {
  favicon: string | null | undefined;
  url: string;
}): string | undefined => {
  try {
    if (!webpage.favicon) {
      const url = new URL(webpage.url);
      return `https://www.google.com/s2/favicons?domain=${url.hostname}&sz=32`;
    }

    const url = new URL(webpage.favicon, webpage.url);
    return url.toString();
  } catch (e) {
    return webpage.favicon?.startsWith("http") ? webpage.favicon : undefined;
  }
};

export const cachedImage = (image: string | undefined) => {
  if (!image) return undefined;

  if (image.startsWith("/api")) return image;

  if (["twimg.com", "licdn.com", "crunchbase.com"].find((d) => image?.includes(d))) {
    const prefix = process.env.NODE_ENV == "development" ? "https://distill.fyi" : "";
    image = `${prefix}/api/images/get?url=${encodeURIComponent(image)}`;
  }
  return image;
};

export function getRandomItem<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here: " + String(x));
}

export function assertIsDefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`${String(value)} is not defined`);
  }
}

export function intersperse<A, B>(a: A[], b: B[]): (A | B)[] {
  const length = Math.max(a.length, b.length);
  const ret = [];
  for (let i = 0; i < length; i++) {
    if (i < a.length) {
      ret.push(a[i]);
    }
    if (i < b.length) {
      ret.push(b[i]);
    }
  }
  return ret;
}

export function updateUnsubscribe(
  self: { unsubscribe: null | (() => void) },
  unsubscribe: () => unknown,
  skipImmediateUnsubscribe?: boolean,
) {
  // unsubscribe any existing subscriptions as the first thing we do
  // this cleans up dev hot-reload issues, but it shouldn't hurt in general
  if (!skipImmediateUnsubscribe) unsubscribe();
  // then, if there was a previous subscription, unsubscribe to that too
  if (self.unsubscribe) self.unsubscribe();
  self.unsubscribe = unsubscribe;
}

export function isJest() {
  return !!process.env.JEST_WORKER_ID;
}

export function uniqueKey(baseKey: string, keys: Set<string>): string {
  let i = 0;
  let key = baseKey;
  while (keys.has(key)) {
    key = `${baseKey}-${i++}`;
  }
  keys.add(key);
  return key;
}
