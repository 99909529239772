/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  CreateListParams,
  ListEntriesResult,
  ListEntryUpdate,
  ListOverviewResult,
  ResolveListEntriesParams,
  UpdateListParams,
} from "@/lists/listService.types";
import {
  ListDetails,
  ListEntryDetails,
  ListEntryResolution,
  ListEntrySpec,
  PagingOptions,
} from "@/types";

import { Connector } from "./apiTypes";

export type CreateListData = CreateListParams["data"];
export type UpdateListData = UpdateListParams["data"];
export type ResolveListEntriesData = Omit<ResolveListEntriesParams["data"], "listId">;

export class ListsResource {
  private readonly name: string;
  constructor(
    public conn: Connector,
    options?: { name?: string },
  ) {
    this.name = options?.name || "lists";
  }

  async list(params: PagingOptions): Promise<ListOverviewResult> {
    const response = await this.conn.axios.get(`/${this.name}`, {
      params,
    });
    return response.data;
  }

  async create(data: CreateListData): Promise<ListDetails> {
    const response = await this.conn.axios.post(`/${this.name}`, data);
    return response.data;
  }

  async get(id: string): Promise<ListDetails> {
    const response = await this.conn.axios.get(`/${this.name}/${id}`);
    return response.data;
  }

  async update(id: string, data: UpdateListData): Promise<ListDetails> {
    const response = await this.conn.axios.put(`/${this.name}/${id}`, data);
    return response.data;
  }

  async delete(id: string): Promise<void> {
    await this.conn.axios.delete(`/${this.name}/${id}`);
  }
}

export class ListEntriesResource {
  private readonly parentName: string;
  private readonly name: string;

  constructor(
    public conn: Connector,
    options?: { parentName?: string; name?: string },
  ) {
    this.parentName = options?.parentName || "lists";
    this.name = options?.name || "entries";
  }

  async list(listId: string, params: PagingOptions): Promise<ListEntriesResult> {
    const response = await this.conn.axios.get(`/${this.parentName}/${listId}/${this.name}`, {
      params,
    });
    return response.data;
  }

  async create(listId: string, data: { entrySpecs: ListEntrySpec[] }): Promise<ListEntryDetails[]> {
    const response = await this.conn.axios.post(`/${this.parentName}/${listId}/${this.name}`, data);
    return response.data;
  }

  async update(listId: string, data: { entries: ListEntryUpdate[] }): Promise<ListEntryDetails[]> {
    const response = await this.conn.axios.put(`/${this.parentName}/${listId}/${this.name}`, data);
    return response.data;
  }

  async delete(listId: string, itemIds: string[]): Promise<Date> {
    const response = await this.conn.axios.delete(`/${this.parentName}/${listId}/${this.name}`, {
      params: { itemIds },
    });
    return response.data;
  }

  async resolve(listId: string, data: ResolveListEntriesData): Promise<ListEntryResolution[]> {
    const response = await this.conn.axios.post<ListEntryResolution[]>(
      `/lists/${listId}/resolve`,
      data,
    );
    return response.data;
  }
}
