import { AttributeSource, EntityType } from "@/types/enums";
import { Prisma } from "@prisma/client";

// add all possible attribute shapes here

export type BaseAttribute = {
  id: string;
  source: AttributeSource;
  updatedAt: Date;
};

export type Attribute =
  | LinkedInProfileAttr
  | LinkedInCompanyProfileAttr
  | UnknownAttr
  | LegacyProfileAttr;

export type LinkedInCompanyProfileAttr = BaseAttribute & {
  attributeType: AttributeType.LinkedinCompanyProfile;
  value: LinkedinCompanyProfile;
};

export type LinkedInProfileAttr = BaseAttribute & {
  attributeType: AttributeType.LinkedinProfile;
  value: LinkedinProfile;
};

export type LegacyProfileAttr = BaseAttribute & {
  attributeType: AttributeType.LegacyPersonProfile;
  value: PersonSummary;
};

export type UnknownAttr = BaseAttribute & {
  attributeType: AttributeType.Unknown;
  value: Prisma.JsonValue;
};

export enum AttributeType {
  LinkedinProfile = "linkedinProfile",
  LinkedinCompanyProfile = "linkedinCompanyProfile",
  LegacyPersonProfile = "legacyPersonProfile",
  Unknown = "unknown",
}

/** only used for non-linkedin profiles */
export type PersonSummary = {
  name: string;
  summary: string;
  altNames?: string;
  about?: string;
  title?: string;
  location?: string;
  approxAge?: string;
  workExperiences: {
    startDate?: string;
    endDate?: string;
    title: string;
    location?: string;
    company: string;
    companyUrl?: string;
    description?: string;
  }[];
  education: {
    startDate?: string;
    endDate?: string;
    degree: string;
    school: string;
  }[];
  interests?: string;
  otherInfo?: string;
};

export type LinkedinCommonProfile = {
  profile: {
    url?: string;
    coverImage?: string;
    profileImage?: string;
    name: string;
    location?: string;
    followers?: string;
  };
};

// linkedin person profile attributes
export type LinkedinProfile = {
  approxAge?: string;
  altNames?: string;
  profile: {
    url?: string;
    coverImage?: string;
    profileImage?: string;
    name: string;
    title?: string;
    location?: string;
    followers?: number | string;
    connections?: number | string;
    mutuals?: string; // i.e. Isaac Wang, Jon Paris, and 78 other mutual connections
    degree?: string;
  };
  about?: string;
  highlights?: string[];
  recentActivity: RecentActivity[];

  experience: WorkExperience[];
  hasMoreExperience?: string; // i.e. Show all 10 experiences
  loadedExtended?: boolean;
  education: Education[];
  volunteer?: Volunteer[];
  hasMoreEducation?: string;
  skills?: string[];
  patents?: string[];
  projects?: string[];
  languages?: string[];
  interests?: string[];
  volunteering?: string[];
};

export type RecentActivity = {
  title: string;
  date: string;
  text: string;
  url: string;
  image?: string;
  kind?: "post" | "article" | "repost";
  articleTitle?: string;
  articleSubtitle?: string;
  originalPost?: RecentActivity;
};

export type DogfoodFeedbackResult = {
  text: string;
  rating: number;
  isLatest: boolean;
  issueCategoryIds?: string[];
};

export type WorkExperience = {
  logo?: string;
  company: string; // may include · employment type
  url?: string;
  totalDuration?: string;
  location?: string;
  entityId?: string;
  positions: {
    title: string;
    type?: string;
    dates: string;
    location?: string;
    description?: string;
  }[];
};

export const workExperienceUrl = (w: WorkExperience) => {
  if (!w.url && !w.company) {
    return "";
  } else if (!w.url || w.url.includes("linkedin.com/search")) {
    return `/search?q=${encodeURIComponent(w.company.split(/[-·]/)[0].trim())}&type=companies`;
  } else {
    return `/profile?url=${encodeURIComponent(w.url)}&name=${encodeURIComponent(
      w.company.split(/[-·]/)[0].trim(),
    )}`;
  }
};

// used for matching work experience with relationship objects
export const workPositionKey = (w: WorkExperience, i: number) =>
  `${
    w.company
      ?.split(/[^\w\s]/, 2)[0]
      .substring(0, 20)
      .trim() || "noco"
  }-${w.positions[i]?.dates?.split("-", 2)[0].trim() || "undated"}`;

export const educationKey = (e: Education) =>
  `${e.institution.substring(0, 20).trim() || "noco"}-${e.degree}`;

export const volunteerKey = (v: Volunteer) =>
  `${v.institution?.substring(0, 20).trim() || "noco"}-${v.role}`;

export enum EmploymentType {
  FullTime = "Full-time",
  PartTime = "Part-time",
  SelfEmployed = "Self-employed",
  Freelance = "Freelance",
  Contract = "Contract",
  Internship = "Internship",
  Apprenticeship = "Apprenticeship",
}

export enum PositionClassification {
  Board = "board",
  Investor = "investor",
  Advisor = "advisor",
  Volunteer = "volunteer",
  Other = "other",
}

export type Education = {
  logo?: string;
  institution: string;
  url: string;
  degree: string;
  dates?: string;
  location?: string;
  description?: string;
};
export type Volunteer = {
  role: string;
  logo?: string;
  institution?: string;
  url?: string;
  dates?: string;
  industry?: string;
  description?: string;
};

// linkedin company profile attributes
export type LinkedinCompanyProfile = {
  profile: {
    url?: string;
    coverImage?: string;
    profileImage?: string;
    name: string;
    tagline?: string;
    industry: string;
    location?: string;
    followers?: string;
    employees?: string;
    website?: string;
  };
  overview: string;
  ticker?: string;
  specialties?: string[];
  recentActivity?: {
    title: string;
    date: string;
    text: string;
    url: string;
    articleTitle?: string;
    articleSubtitle?: string;
    image?: string;
    kind: "post" | "article" | "repost";
    originalPost?: RecentActivity;
  }[];
  insights?: {
    employees?: string;
    headcountGrowth?: string;
    medianTenure?: string;
    employeeGraph?: string;
    headcounts?: {
      date: string;
      headcount: number;
    }[];
  };
  // New fields
  fundingData?: {
    lastFundingRound?: {
      moneyRaised?: string;
      fundingType?: string;
      announcedDate?: {
        month: number;
        day: number;
        year: number;
      };
    };
    numFundingRounds?: number;
  };
  headquarter?: {
    country?: string;
    geographicArea?: string;
    city?: string;
    postalCode?: string;
  };
  foundedYear?: number;
  companyType?: string;
};

// legacy person attributes
export type PersonAttributes = {
  name: string;
  summary: string;
  altNames?: string;
  about?: string;
  location?: string;
  approxAge?: string;
  birthday?: string;
  workExperiences: {
    startDate?: string;
    endDate?: string;
    title: string;
    location?: string;
    company: string;
    companyUrl?: string;
    description?: string;
  }[];
  education: {
    startDate?: string;
    endDate?: string;
    degree: string;
    school: string;
  }[];
  interests?: string;
  otherInfo?: string;
};

export type LinkedinSearchResults = {
  type: string | null;
  total: string | null;
  results: LinkedinSearchResult[];
};

export type LinkedinSearchResult = {
  name: string;
  title: string;
  location: string;
  insight?: string;
  degree?: string;
  profileImage?: string;
  url: string;
  summary?: string;
  mutualConnectionsCount?: number;
  type: EntityType;
  followers?: string;
};

export function isLinkedinSearchResult(l: unknown): l is LinkedinSearchResult {
  return (l as LinkedinSearchResult)?.name !== undefined;
}

export function isLinkedinProfile(l: unknown): l is LinkedinCommonProfile {
  return (l as LinkedinCommonProfile)?.profile !== undefined;
}

export function isLinkedinPersonProfile(l: unknown): l is LinkedinProfile {
  return (
    (l as LinkedinProfile)?.profile?.name !== undefined &&
    (l as LinkedinCompanyProfile)?.overview == undefined
  );
}

export function isLegacyPersonProfile(l: unknown): l is PersonSummary {
  return (l as PersonSummary)?.summary !== undefined && (l as PersonSummary)?.name !== undefined;
}

export function isLinkedinCompanyProfile(l: unknown): l is LinkedinCompanyProfile {
  return (
    (l as LinkedinCompanyProfile)?.profile?.name !== undefined &&
    (l as LinkedinCompanyProfile)?.overview !== undefined
  );
}

// --- private attributes

export const PrivateAttributeType = {
  MutualConnections: "mutual-connections",
} as const;
export type PrivateAttributeType = (typeof PrivateAttributeType)[keyof typeof PrivateAttributeType];

export type PrivateAttributeMutualConnection = {
  degree: string;
  mutualConnectionsCount: number; // this is the number of mutual connections the Distill user also has with this linkedin user
  name: string;
  linkedinUrl: string;
  profileImage?: string;
};

export enum MutualConnectionsStatus {
  Reloading = "reloading",
  Pending = "pending",
  Current = "current",
}

export type MutualConnectionsResponse = {
  connections: PrivateAttributeMutualConnection[];
  status: MutualConnectionsStatus;
};

export type PrivateAttributeValue = {
  [PrivateAttributeType.MutualConnections]: PrivateAttributeMutualConnection;
};

// Define types for Linkedin API responses
export type ProfileApiResponse = {
  included: ProfileApiIncludedItem[];
};

export type ProfileApiIncludedItem = {
  $type: string;
  firstName?: string;
  lastName?: string;
  headline?: string;
  geoLocationName?: string;
  state?: string;
  geoCountryName?: string;
  entityUrn?: string;
  publicIdentifier?: string;
  summary?: string;
  picture?: Picture;
  backgroundImage?: Picture;
};

export type Picture = {
  rootUrl?: string;
  artifacts?: Artifact[];
};

export type Artifact = {
  fileIdentifyingUrlPathSegment?: string;
};

export type ExperienceApiResponse = {
  data: {
    data: {
      identityDashProfileComponentsByPagedListComponent?: {
        elements: ExperienceComponent[];
      };
    };
  };
  included: ExperienceIncludedItem[];
};

export type ExperienceComponent = {
  components?: {
    entityComponent: ExperienceEntityComponent;
  };
};

export type ExperienceEntityComponent = {
  titleV2?: NestedTextObject;
  caption?: TextObject;
  metadata?: TextObject;
  subtitle?: TextObject;
  image?: ImageObject;
  textActionTarget?: string;
  subComponents?: {
    components: {
      components: {
        "*pagedListComponent"?: string;
        fixedListComponent?: {
          components: {
            components: {
              entityComponent?: ExperienceEntityComponent;
              textComponent?: NestedTextObject;
            };
          }[];
        };
      };
    }[];
  };
};

export type EducationApiResponse = {
  data: {
    data: {
      identityDashProfileComponentsByPagedListComponent?: {
        elements: EducationComponent[];
      };
    };
  };
  included: EducationIncludedItem[];
};

export type EducationComponent = {
  components?: {
    entityComponent: EducationEntityComponent;
  };
};

export type EducationEntityComponent = {
  titleV2?: NestedTextObject;
  subtitle?: TextObject;
  caption?: TextObject;
  metadata?: TextObject;
  image?: ImageObject;
  subComponents?: {
    components: {
      components: {
        fixedListComponent?: {
          components: {
            components: {
              textComponent?: NestedTextObject;
            };
          }[];
        };
      };
    }[];
  };
};

export type DashboardApiResponse = {
  included: DashboardIncludedItem[];
};

export type VolunteerApiResponse = {
  data?: {
    data?: {
      identityDashProfileComponentsBySectionType?: {
        elements: VolunteerComponent[];
      };
    };
  };
  included?: VolunteerIncludedItem[];
};

export type VolunteerComponent = {
  components?: {
    entityComponent: VolunteerEntityComponent;
  };
};

export type VolunteerEntityComponent = {
  titleV2?: NestedTextObject;
  subtitle?: TextObject;
  caption?: TextObject;
  metadata?: TextObject;
  image?: ImageObject;
  subComponents?: {
    components: {
      components: {
        textComponent?: NestedTextObject;
      };
    }[];
  };
};

export type TextObject = {
  text: string;
};
export type NestedTextObject = {
  text: { text: string };
};
export type ImageObject = {
  actionTarget?: string;
  attributes?: ImageAttribute[];
};

export type ImageAttribute = {
  detailData: {
    "*companyLogo"?: string;
  };
};

export type ExperienceIncludedItem = {
  entityUrn?: string;
  logoResolutionResult?: {
    vectorImage?: {
      rootUrl?: string;
      artifacts?: Artifact[];
    };
  };
  components?: {
    elements: ExperienceComponent[];
  };
};

export type EducationIncludedItem = {
  entityUrn?: string;
  logoResolutionResult?: {
    vectorImage?: {
      rootUrl?: string;
      artifacts?: Artifact[];
    };
  };
};

export type VolunteerIncludedItem = {
  entityUrn: string;
  components?: {
    elements: VolunteerComponent[];
  };
};

export type DashboardIncludedItem = {
  $type?: string;
  entityUrn: string;
  followerCount?: number;
  connections?: {
    paging?: {
      total?: number;
    };
  };
};

export type ActivityApiResponse = {
  included: ActivityIncludedItem[];
  data: {
    data: {
      feedDashProfileUpdatesByMemberShareFeed: {
        "*elements": string[];
      };
    };
  };
};

export type ActivityIncludedItem = {
  entityUrn: string;
  content?: {
    articleComponent?: ArticleComponent;
    imageComponent?: ImageComponent;
  };
  commentary?: NestedTextObject;
  actor: {
    name?: TextObject;
    subDescription: TextObject;
  };
  socialContent?: {
    shareUrl?: string;
  };
};

export type ArticleComponent = {
  title: TextObject;
  description?: TextObject;
  navigationContext: {
    actionTarget: string;
  };
  smallImage?: {
    attributes?: {
      detailData: {
        vectorImage: {
          rootUrl: string;
          artifacts: Artifact[];
        };
      };
    }[];
  };
};

export type ImageComponent = {
  images?: {
    attributes?: {
      detailData: {
        vectorImage: {
          rootUrl: string;
          artifacts: Artifact[];
        };
      };
    }[];
  }[];
};
