import { Cheerio, CheerioAPI, Element, load } from "cheerio";

import { loggerWithPrefix } from "@/lib/logger";
import { LinkedinCompanyProfile } from "@/types";
import { objToString } from "@/utils/entityUtils";

const logger = loggerWithPrefix("[linkedinCompanyParser]");

export class LinkedInCompanyProfileParser {
  $: CheerioAPI;

  // linkedin.com/company/foo and linkedin.com/company/foo/about
  constructor(public html: string) {
    this.$ = load(html);
  }

  parse(url?: string): LinkedinCompanyProfile {
    const { $ } = this;
    const mainSection = $("main");

    const profile: LinkedinCompanyProfile = {
      profile: { url, name: "", industry: "" },
      overview: "",
      recentActivity: [],
    };

    this.parseProfileCard(mainSection.find("section.org-top-card"), profile);

    let aboutSection = mainSection.find('[data-test-id="about-us"]');
    if (aboutSection.length == 0) aboutSection = mainSection.find(".org-about-module-wrapper");
    if (aboutSection.length == 0) aboutSection = mainSection.find(".core-section-container").eq(0);
    this.parseAbout(aboutSection, profile);

    this.parseInsights(mainSection.find(".org-highlight-insights-module__card-spacing"), profile);

    this.parseRecentActivity(mainSection.find(".org-highlight-posts-module"), profile);

    return profile;
  }

  // extract cover and profile image
  // extract basics: name, title, summary, location, connections, mutuals, your relationship
  parseProfileCard(section: Cheerio<Element>, profile: LinkedinCompanyProfile) {
    const { $ } = this;

    // if we already saw a profile name, we can skip this section
    if (profile.profile.name) return;

    // Cover Image
    const coverImageStyle = section.find(".org-cropped-image__cover-image").attr("style");
    const coverImageUrl = coverImageStyle?.match(/url\((.*?)\)/)?.[1];

    // Profile Image
    const profileImageUrl = section.find(".org-top-card-primary-content__logo").attr("src");

    // Name
    const name = section.find("h1").text().trim();

    // Tagline
    const tagline = section.find(".org-top-card-summary__tagline").text().trim();

    const infoList = section.find(".org-top-card-summary-info-list");
    const items = infoList.find(".org-top-card-summary-info-list__info-item");

    const itemText = items.map((i, el) => $(el).text().trim().replace(/\s+/, " ")).get();
    // ordering: industry, location, followers, employees
    // location is optional, i'm not sure about the others

    const industry = itemText.shift()!;
    let location: string | undefined = undefined;
    if (!itemText[0]?.includes("followers")) {
      location = itemText.shift();
    }
    const followers = itemText.find((f) => f.includes("followers"));
    const employees = itemText.find((f) => f.includes("employees"));

    const overflowMenu = section.find(".org-overflow-menu");
    const menuLink = overflowMenu.find("a").eq(0);
    let website =
      menuLink.text().toLowerCase().includes("website") ?
        menuLink.attr("href")
      : section.find(".org-top-card-primary-actions__external-link").closest("a").attr("href");

    if (website?.startsWith("/") || website?.includes("linkedin.com")) website = undefined;

    // Assign extracted data to the profile object
    profile.profile = {
      coverImage: coverImageUrl,
      profileImage: profileImageUrl,
      name,
      tagline,
      location,
      followers,
      industry,
      employees,
      website: website?.replace("http://", "https://") || undefined,
    };
  }

  parseAbout(section: Cheerio<Element>, profile: LinkedinCompanyProfile) {
    let text = section.find(".org-about-module__description").text().trim();
    text = text.replace(/\.\.\. see more/g, "");
    profile.overview = text;

    if (!profile.profile.website) {
      let website = section.find('[data-tracking-control-name="about_website"]').attr("href");
      if (!website) website = section.find(".org-contact-info__content a").attr("href");

      if (!website)
        website = section
          .find(".org-top-card-primary-actions__external-link")
          .closest("a")
          .attr("href");
      if (website?.startsWith("/") || website?.includes("linkedin.com")) website = undefined;
      profile.profile.website = website?.replace("http://", "https://");
    }

    const about = section.find(".org-about-module__content-improvement");
    if (about.length) {
      const stockQuote = about.find(".org-stockquote-info");
      if (stockQuote) {
        const ticker = stockQuote.find(".org-stockquote-info__content-left").eq(0).text().trim();
        if (ticker) profile.ticker = ticker;
      }
    }
  }

  parseInsights(section: Cheerio<Element>, profile: LinkedinCompanyProfile) {
    const { $ } = this;
    if (!section.length) return;
    profile.insights = {};

    const links = section.find("a.full-width");
    const insightText = links.map((i, el) => $(el).text().trim()).get();
    insightText.forEach((text, i) => {
      if (text.includes("headcount")) profile.insights!.headcountGrowth = text;
      if (text.includes("tenure")) profile.insights!.medianTenure = text;
    });

    const graph = section.find(".highcharts-wrapper");
    if (graph.length) profile.insights.employeeGraph = graph.html()!;
  }

  parseRecentActivity(section: Cheerio<Element>, profile: LinkedinCompanyProfile) {
    // const { $ } = this;
    // const activities: typeof profile.recentActivity = [];
    // const recentActivity = section.find("ul li");
    // recentActivity.each((i, activity) => {
    //   const activityHtml = $(activity);
    //   const allLinks = activityHtml.find("a.app-aware-link");
    //   if (!allLinks.length) return;
    //   const headerLink = allLinks.eq(0);
    //   const title = headerLink
    //     .find("[aria-hidden=true]")
    //     .first()
    //     .text()
    //     .trim()
    //     .replace(/\s+/g, " ");
    //   const date = headerLink.find(".visually-hidden").text().trim();
    //   // get remainder of links
    //   const bodyLinks = allLinks.slice(1);
    //   const image = bodyLinks.find("img").first().attr("src");
    //   const ariaLabels = bodyLinks
    //     .map((i, el) => $(el).attr("aria-label"))
    //     .get()
    //     .map((p) =>
    //       p
    //         .replace(/View full post(. )?/, "")
    //         .replace(/^Image$/, "")
    //         .replace(/\s+/g, " ")
    //         .trim(),
    //     )
    //     .filter(Boolean);
    //   activities.push({
    //     url: headerLink.attr("href")!,
    //     title,
    //     date,
    //     image,
    //     text: uniq(ariaLabels).join("\n"),
    //   });
    // });
    // profile.recentActivity = activities;
  }
}

export function linkedinCompanySummary(profile: LinkedinCompanyProfile): string {
  const parts: string[] = [];

  if (!profile.profile) return objToString(profile);

  if (profile.profile.tagline) parts.push(profile.profile.tagline);
  if (profile.profile.location) parts.push(profile.profile.location);
  if (profile.profile.industry) parts.push(profile.profile.industry);
  if (profile.profile.employees) parts.push(profile.profile.employees);
  if (profile.overview) parts.push("About: " + profile.overview);

  if (profile.insights) {
    parts.push("Insights:");
    parts.push(objToString(profile.insights));
  }

  return parts.join("\n");
}
