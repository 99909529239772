import API from "@/client/api";
import { logger } from "@/lib/logger";
import { CHROME_EXTENSION_IDS, uiStore } from "@/stores/uiStore";
import {
  LinkWithDescription,
  LinkedinSearchResults,
  UserMeta,
  UserWithMeta,
  userMeta,
} from "@/types";

const TIMEOUT = 10_000;

type CanScrapeResponse = { canScrape?: number };
type ScrapeResponse = { scraped?: string; html?: string; failed?: string };

class ExtensionScraper {
  activeExtensionId?: string | null;
  scrapeLevel = 0;
  timedOut = false;

  shouldExtensionScrape = (meta: UserMeta) => {
    // default to extension scrape
    return meta.exsc !== false;
  };

  async init(user: UserWithMeta) {
    if (typeof chrome === "undefined") {
      logger.warn("chrome not found");
      return;
    }

    const meta = userMeta(user);
    if (!this.shouldExtensionScrape(meta)) {
      this.activeExtensionId = undefined;
      return;
    }

    // temporarily disable extension scraper for dev users so we can test voyager API
    if (meta.exsc !== true && uiStore.showDevTools()) {
      logger.info("extension scraper disabled");
      return;
    }

    if (this.activeExtensionId !== undefined) return;

    this.activeExtensionId = null;
    await new Promise<void>((resolve) => {
      CHROME_EXTENSION_IDS.forEach((extensionId) => {
        try {
          chrome?.runtime?.sendMessage(
            extensionId,
            { canScrape: true },
            (response: CanScrapeResponse) => {
              // we access the last error so we don't log to console.
              const _err = chrome.runtime.lastError;
              if (this.activeExtensionId) return;
              if (response) {
                logger.info("[extensionScraper] init response:", response, extensionId);
                if (response.canScrape) {
                  this.activeExtensionId = extensionId;
                  this.scrapeLevel = Number(response.canScrape);
                  resolve();
                }
              }
            },
          );
        } catch (e) {
          // doesn't exist, continue to next one
        }
      });
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }

  async scrapeIfAvailable(url: string, minVer?: number): Promise<string | undefined> {
    if (this.activeExtensionId && (!minVer || this.scrapeLevel >= minVer)) {
      return await new Promise((resolve, reject) => {
        chrome?.runtime?.sendMessage(
          this.activeExtensionId,
          { scrape: url },
          async (response: ScrapeResponse) => {
            if (response) {
              resolve(response.html);
            } else {
              resolve(undefined);
            }
          },
        );
      });
    }

    return undefined;
  }

  async searchWeb(query: { q: string; page?: number }): Promise<LinkWithDescription[]> {
    logger.info("search web", this.activeExtensionId ? "via extension" : "via api");
    if (this.activeExtensionId && !this.timedOut) {
      if (!query.page) query.page = 0;
      try {
        const url = `https://www.google.com/search?q=${encodeURIComponent(query.q)}&start=${
          query.page * 10
        }`;
        return await new Promise((resolve, reject) => {
          let responded = false;
          setTimeout(() => {
            if (!responded) {
              this.timedOut = true;
              reject(new Error("Extension timed out"));
            }
          }, TIMEOUT);

          chrome?.runtime?.sendMessage(
            this.activeExtensionId,
            { scrape: url },
            async (response: ScrapeResponse) => {
              if (response?.scraped && response?.html) {
                logger.info("[extensionScraper] scrape response:", response);
                const result = await API.searchScraped({
                  url: response.scraped,
                  html: response.html,
                });
                responded = true;
                resolve(result as LinkWithDescription[]);
              } else {
                reject(detectScraperFailure(response));
              }
            },
          );
        });
      } catch (e) {
        logger.warn("extension error", e);
      }
    }

    return await API.searchWeb(query);
  }

  async searchLi(query: {
    q: string;
    type: "all" | "companies" | "people";
    page?: number;
    shouldSearchPersonalConnections?: boolean; // if true, q is ignored
  }): Promise<LinkedinSearchResults> {
    logger.info("search web", this.activeExtensionId ? "via extension" : "via api");
    /*if (this.activeExtensionId && !this.timedOut) {
      try {
        if (!query.page) query.page = 0;

        const urlObj = new URL(`https://www.linkedin.com/search/results/${query.type}`);
        urlObj.searchParams.set("page", `${query.page + 1}`);

        if (query.shouldSearchPersonalConnections) {
          urlObj.searchParams.set("network", '["F"]');
          urlObj.searchParams.set("origin", "MEMBER_PROFILE_CANNED_SEARCH");
          urlObj.searchParams.set("sid", "kHA");
        } else {
          urlObj.searchParams.set("keywords", query.q);
        }
        const url = urlObj.toString();

        return await new Promise((resolve, reject) => {
          let responded = false;
          setTimeout(() => {
            if (!responded) {
              this.timedOut = true;
              reject(new Error("Extension timed out"));
            }
          }, TIMEOUT);

          chrome?.runtime?.sendMessage(
            this.activeExtensionId,
            { scrape: url },
            async (response: ScrapeResponse) => {
              if (response?.scraped && response?.html) {
                logger.info("[extensionScraper] scrape response:", response);
                const result = await API.searchScraped({
                  url: response.scraped,
                  html: response.html,
                });
                responded = true;
                resolve(result as LinkedinSearchResults);
              } else {
                reject(detectScraperFailure(response));
              }
            },
          );
        });
      } catch (e) {
        logger.warn("extension li search error:", e);
      }
    }*/

    return await API.searchLi(query);
  }
}

const detectScraperFailure = (response: ScrapeResponse) => {
  if (response?.failed) {
    return new Error(response.failed);
  }
  if (response) {
    logger.info(response);
    return new Error("unknown response");
  }
  return new Error("no response");
};

const extensionScraper = new ExtensionScraper();
export default extensionScraper;
