import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

import { uiStore } from "@/stores/uiStore";
import { ServerProps, UserWithMeta } from "@/types";
import { useStore } from "@nanostores/react";
import { entityStore } from "@/stores/entityStore";

export const useDevTools = () => {
  const user = useStore(uiStore.user);
  if (!user) return false;
  return uiStore.showDevTools();
};

export const useCanEdit = () => {
  const isYou = useStore(entityStore.isYou);
  const isDev = useDevTools();
  return isDev || isYou;
};

export function useLoggedOutUI() {
  useUI({});
}

export function useUI(props: ServerProps) {
  const session = useSession();
  const router = useRouter();

  useEffect(() => {
    uiStore.init(props);
  }, [props]);

  useEffect(() => {
    uiStore.router = router;
  }, [router]);

  useEffect(() => {
    if (props.user) {
      uiStore.setUser(props.user);
    } else if (session.data?.user) {
      uiStore.setUser({ ...session.data.user, meta: null } as UserWithMeta);
    }
  }, [session, props.user]);

  return session;
}
